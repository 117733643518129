import { createStore } from 'vuex'

export default createStore({
  state: {
    lang: 'en',

    translation: {
        'en': {
            navHome: 'Home',
            navAbout: 'About us',
            navRoadmap: 'Roadmap',
            navToken: 'Tokenomics',
            navProg: 'Affiliate program',
            login: 'Account',
            firstWindowTitle: 'EASY-TO-UNDERSTAND BUSINESS FOR EVERYONE',
            firstWindow: 'We, Aurous.Finance group, present our vision for the future.',
            secondWindowTitle1: 'HISTORY',
            secondWindowTitle2: ' AND EXPERIENCE ARE OUR GREATEST ',
            secondWindowTitle3: 'ASSETS',
            advantagesTitle1: 'GUARANTEE AND ASSURANCE',
            advantagesText1: 'When you invest in us, you invest in your future. Doing business with us means safe and secure investments for years to come, without fear of bankruptcy or technical default.',
            advantagesTitle2: 'IT IS AVAILABLE TO ALL',
            advantagesText2: 'Our product is real and understandable for everyone. Gold is a fundamental component of the economy worldwide, but few people are privileged to mine it.',
            experience1: 'We began our journey in 2017, and continue to grow the digital relationship and business industry.',
            experience2: 'Since 2018, we have been improving and developing the industry and are one of the biggest players in the local market.',
            experience3: 'Design and start of development of AUROUS.FINANCE and GOLDEN.FEVERS projects',
            roadmap: 'Roadmap',
            thirdTitle1: 'Roadmap',
            thirdTitle2: ' for a secure ',
            thirdTitle3: 'future',
            roadmapItemTitle1: 'Pre Sales',
            roadmapItem1: 'Pre-sales of tokens from our AUF and GAT ecosystem.',
            roadmapItemTitle2: 'Plant Launch',
            roadmapItem2: 'For more than 5 years, we have been working closely with the Government of Sudan and the Ministry of Economy on gold mining.',
            roadmapItemTitle31: 'Economic game launch',
            roadmapItemTitle32: 'Economic game launch',
            roadmapItem3: 'We are developing a mobile game in Tycoon genre, where the player will be able to feel himself as the owner of a gold mining company. Game will fascinate players with its addictiveness and investors with its profitability.',
            roadmapItemTitle4: 'GOLDEN FEVERS',
            roadmapItem4: 'GOLDEN FEVERS is our NFT project which is in the final stages of development.',
            fourthTitle1: 'TOKENOMICS',
            fourthTitle2: ', STRATEGY AND ',
            fourthTitle3: 'SUCCESS',
            tokenPrice: 'PRICE',
            tokenIncome: 'Income',
            tokenDay: 'DAY',
            tokenAufTitle: 'TOKEN AUF',
            tokenAufPrice: '$0.1 USD',
            tokenAuf: 'Bonus of 2.5% to coin rate for every USD turnover. It has a limit of x3 factor. Maximum growth rate up to 0.35 USD. The principal of deposit is non-withdrawable. Profits start to be generated the next day after coins are sent to the staking. Accrued daily interest is available for withdrawal every day (not less than 10 USD).',
            tokenGatTitle: 'TOKEN GAT',
            tokenGatPrice: '= G.OF GOLD/USD',
            tokenGat: 'Freezing of the principal of deposit is 15 months. Profit accrual and withdrawal from GAT token income is possible after 90 days. Profits are credited daily and available for withdrawal every 15th and 30th day (in February 15 and 28/29). The issue is 21,000,000 coins. For keeping the profit in GAT token with a fixed deposit amount longer than 30 days, an additional 5% “Coin Weight” is given every 31 days.',
            strategy: 'STRATEGY',
            stBuy: 'BUY',
            stFor: 'FOR',
            stDay: 'DAY',
            stBIncome: 'BASIC INCOME',
            stDiscount: 'discount',
            stMIncome: 'MAXIMUM INCOME',
            stDis1: 'FOR 0.01 G.OF GOLD/USD',
            stDis2: 'TOKENS GAT FOR $1 USD',
            stGet: 'GET',
            stIncome: 'INCOME',
            affiliate1: 'AFFILIATE ',
            affiliate2: 'PROGRAM',
            affiliateText1: 'Each affiliate has 10 levels of depth. Everyone who successfully recommends the project receives an affiliate program bonus.',
            affiliateText2: 'All payments are made in AUF coin and are available at the moment of receipt. Withdrawal of referral rewards is not limited and is made at random by the user himself, but not less than 10$.',
            level: 'level',

            detailsTitle: 'Read more',
            details1: 'Bonus 2.5% to the coin rate for every 100,000 coins sold',
            details2: 'Profit accrual and withdrawal from GAT token income is possible after 90 days. Profits will be accrued daily and are available for withdrawal every 15th and 30th day (in February on the 15th and 28th/29th)',
            details3: 'When buying a GAT token with an AUF token, the discount is 50% and stimulates the growth of the AUF token',
            details4: 'When buying a GAT token with USD, you will get 30% more tokens for the same amount',
            details5: 'For holding a stake for more than 90 days, every subsequent 30 days a“Coin Weight”of 5% is charged',

        },
        'it': {
          navHome: 'Home',
          navAbout: 'Chi siamo',
          navRoadmap: 'Tabella di marcia',
          navToken: 'Tokenomics',
          navProg: 'Programma affiliato',
          login: 'Account',
          firstWindowTitle: 'AFFARI CHIARI PER TUTTI',
          firstWindow: 'Siamo il team Aurous.Finance che presenta la nostra visione per il futuro.',
          secondWindowTitle1: 'STORIA',
          secondWindowTitle2: ' ED ESPERIENZA I NOSTRI PRINCIPALI ',
          secondWindowTitle3: 'VANTAGGI',
          advantagesTitle1: 'GARANZIA E FIDUCIA',
          advantagesText1: 'Investendo in noi, stai investendo nel tuo futuro. Fare affari con noi è un investimento sicuro e affidabile per molti anni senza timore di bancarotta o default tecnico.',
          advantagesTitle2: 'DISPONIBILE PER TUTTI',
          advantagesText2: "Il nostro prodotto è reale e comprensibile per ogni utente. L'oro è una parte fondamentale dell'economia mondiale, ma poche persone hanno il privilegio di estrarlo.",
          experience1: 'Abbiamo iniziato il nostro viaggio nel 2017 e continuiamo a sviluppare le relazioni digitali e il settore commerciale.',
          experience2: 'Dal 2018, abbiamo migliorato e sviluppato il settore e siamo uno dei maggiori attori nel mercato locale.',
          experience3: 'Ideazione e sviluppo dei progetti AUROUS.FINANCE e GOLDEN.FEVERS',
          roadmap: 'Tabella di marcia',
          thirdTitle1: 'Tabella di marcia',
          thirdTitle2: ' per un ',
          thirdTitle3: 'futuro sicuro',
          roadmapItemTitle1: 'Prevendita',
          roadmapItem1: "Prevendita dei nostri token dell'ecosistema AUF e GAT.",
          roadmapItemTitle2: 'Inizio della fabbrica',
          roadmapItem2: "Da oltre 5 anni collaboriamo strettamente con il governo del Sudan e il Ministero dell'Economia nel campo dell'estrazione dell'oro.",
          roadmapItemTitle31: 'Inizio del gioco economico',
          roadmapItemTitle32: 'Inizio del gioco economico',
          roadmapItem3: "Stiamo sviluppando un gioco per cellulare nel genere Tycoon, in cui il giocatore potrà sentirsi il proprietario di una società di estrazione dell'oro. Il gioco affascinerà i giocatori con la sua dipendenza e gli investitori con la sua redditività.",
          roadmapItemTitle4: 'GOLDEN FEVERS',
          roadmapItem4: 'GOLDEN FEVERS è il nostro progetto NFT che è nelle fasi finali di sviluppo.',
          fourthTitle1: 'TOKENOMICS',
          fourthTitle2: ', STRATEGIA E ',
          fourthTitle3: 'SUCCESSO',
          tokenPrice: 'prezzo',
          tokenIncome: 'reddito',
          tokenDay: 'GIORNO',
          tokenAufTitle: 'TOKEN AUF',
          tokenAufPrice: '$0.1 USD',
          tokenAuf: "Bonus del 2,5% sul tasso di cambio per ogni fatturato in USD. Ha un limite di fattore x3. Tasso di crescita massimo fino a 0,35 USD. Il capitale del deposito non è prelevabile. I profitti iniziano a essere generati il   giorno successivo dopo che le monete sono state inviate allo staking. L'interesse giornaliero maturato è disponibile per il prelievo ogni giorno (non meno di 10 USD).",
          tokenGatTitle: 'TOKEN GAP',
          tokenGatPrice: '= 0.01 G.DI GOLOTA/USD',
          tokenGat: `Il congelamento del capitale di deposito è di 15 mesi. L'accumulo di profitti e il prelievo dal reddito dei token GAT è possibile dopo 90 giorni. I profitti vengono accreditati giornalmente e disponibili per il prelievo ogni 15 e 30 giorni (nel 15 e 28/29 febbraio). L'emissione è di 21.000.000 di monete. Per mantenere il profitto nel token GAT con un importo di deposito fisso superiore a 30 giorni, viene assegnato un ulteriore "peso della moneta" del 5% ogni 31 giorni.`,
          strategy: 'strategia',
          stBuy: 'COMPRARE',
          stFor: 'per',
          stDay: 'GIORNO',
          stBIncome: 'REDDITO DI BASE',
          stDiscount: 'SCONTO',
          stMIncome: 'REDDITO MASSIMO',
          stDis1: '= 0.01 G.DI GOLOTA/USD',
          stDis2: 'Token GAT per $1 usd',
          stGet: 'ottenere',
          stIncome: 'reddito',
          affiliate1: 'PROGRAMMA ',
          affiliate2: 'AFFILIATO',
          affiliateText1: 'Ogni affiliato ha 10 livelli di profondità. Chiunque raccomandi con successo il progetto riceve un bonus del programma di affiliazione.',
          affiliateText2: `Tutti i pagamenti vengono effettuati in moneta AUF e sono disponibili al momento della ricezione. Il ritiro dei premi di riferimento non è limitato e viene effettuato a caso dall'utente stesso, ma non meno di $ 10.`,
          level: 'livello',

          detailsTitle: 'Leggi di più',
          details1: 'Bonus del 2,5% sul tasso di cambio per ogni 100.000 monete vendute',
          details2: 'La maturazione del profitto e il prelievo di fondi dal reddito del token GAT è possibile dopo 90 giorni. Il profitto viene accumulato giornalmente ed è disponibile per il prelievo ogni 15 e 30 (nel 15 e 28/29 febbraio)',
          details3: 'Quando si acquista un token GAT con un token AUF, lo sconto è del 50% e stimola la crescita del token AUF',
          details4: 'Quando acquisti un token GAT con USD otterrai il 30% in più di token per lo stesso importo',
          details5: 'Per detenere una puntata per più di 90 giorni, ogni 30 giorni successivi viene addebitato un "Peso moneta" del 5%',

        },
        'ru': {
            navHome: 'Главная',
            navAbout: 'О нас',
            navRoadmap: 'Дорожная карта',
            navToken: 'Токеномика',
            navProg: 'Партнерская программа',
            login: 'Личный кабинет',
            firstWindowTitle: 'Понятный бизнес для каждого',
            firstWindow: 'Мы команда Aurous.Finance представляем наше видение будущего.',
            secondWindowTitle1: 'история',
            secondWindowTitle2: ' и опыт наши главные ',
            secondWindowTitle3: 'преимущества',
            advantagesTitle1: 'Гарантия и уверенность',
            advantagesText1: 'Инвестируя в нас, вы инвестируете в свое будущее. Бизнес с нами – это безопасные и надежные вложения на долгие годы без страха банкротства или технического дефолта.',
            advantagesTitle2: 'Доступно для всех',
            advantagesText2: 'Наш продукт реален и понятен каждому пользователю. Золото является фундаментальной составляющей экономики во всем мире, но немногие люди имеют привилегии в его добыче.',
            experience1: 'Мы начали свой путь в 2017 году и продолжаем развивать отрасль цифровых отношений и бизнеса.',
            experience2: 'С 2018 года мы совершенствуем и развиваем отрасль и являемся одними из крупнейших игроков локального рынка.',
            experience3: 'Проектирование и начало разработки проектов AUROUS.FINANCE и GOLDEN.FEVERS',
            roadmap: 'Дорожная карта',
            thirdTitle1: 'Дорожная карта',
            thirdTitle2: ' безопасного ',
            thirdTitle3: 'будущего',
            roadmapItemTitle1: 'Pre Sales',
            roadmapItem1: 'Предварительные продажи токенов нашей экосистемы AUF и GAT.',
            roadmapItemTitle2: 'Старт завода',
            roadmapItem2: 'Более 5 лет мы плотно сотрудничаем с правительством Судана и Министерством Экономики в сфере добычи золота.',
            roadmapItemTitle31: 'Старт экономи-ческой игры',
            roadmapItemTitle32: 'Старт экономической игры',
            roadmapItem3: 'Мы разрабатываем мобильную игру в жанре Tycoon. Где игрок сможет почувствовать себя владельцем золотодобывающей компании. Игра будет захватывать игроков своей увлекательностью, а инвесторов своей доходностью.',
            roadmapItemTitle4: 'GOLDEN FEVERS',
            roadmapItem4: 'GOLDEN FEVERS - это наш NFT проект, который находится на финальном этапе разработки.',
            fourthTitle1: 'ТОКЕНОМИКА',
            fourthTitle2: ', СТРАТЕГИЯ И ',
            fourthTitle3: 'УСПЕХ',
            tokenPrice: 'ЦЕНА',
            tokenIncome: 'доход',
            tokenDay: 'ДЕНЬ',
            tokenAufTitle: 'ТОКЕН Аuf',
            tokenAufPrice: '$0.1 USD',
            tokenAuf: 'Бонус 2.5% к курсу монеты за каждые 100 000 USD оборота. Имеет ограничение х3 фактора. Максимальный рост курса до 0.35 USD. Тело депозита невыводное. Прибыль начинает генерироваться на следующий день после отправки монет в стейкинг. Начисленные ежедневные проценты доступны к выводу каждый день (не менее 10 USD).',
            tokenGatTitle: 'ТОКЕН gat',
            tokenGatPrice: '= 0.01 гр.золота/USD',
            tokenGat: 'Заморозка тела депозита – 15 месяцев. Начисление прибыли и вывод средств от дохода токена GAT возможен после 90 дней. Прибыль начисляется ежедневно и доступна к выводу каждое 15 и 30 число (в феврале 15 и 28/29). Эмиссия 21 000 000 монет. За удержание прибыли в токене GAT при неизменном теле депозита более 30 дней дополнительно начисляется «Вес монеты» в размере 5% каждый 31 день.',
            
            strategy: 'стратегия',
            stBuy: 'КУПИТЬ',
            stFor: 'за',
            stDay: 'ДЕНЬ',
            stBIncome: 'БАЗОВЫЙ ДОХОД',
            stDiscount: 'СКИДКА',
            stMIncome: 'МАКСИМАЛЬНЫЙ ДОХОД',
            stDis1: 'за 0.01 ГР.ЗОЛОТА/USD',
            stDis2: 'ТОКЕНОВ GAT за $1 usd',
            stGet: 'получить',
            stIncome: 'доход',

            affiliate1: 'Партнерская ',
            affiliate2: 'программа',
            affiliateText1: 'Каждый партнер имеет 10 уровней глубины. Успешно рекомендуя проект, каждый желающий, получает бонус партнерской программы.',
            affiliateText2: 'Все выплаты производятся в монете AUF и доступны в момент получения. Вывод реферальных вознаграждений не ограничен и производится в произвольном порядке самого пользователя, но не менее 10$.',
            level: 'уровень',

            detailsTitle: 'Подробнее',
            details1: 'Бонус 2.5% к курсу монеты за каждый 1 000 000 проданных монет',
            details2: 'Начисление прибыли и вывод средств от дохода токена GAT возможен после 90 дней. Прибыль начисляется ежедневно и доступна к выводу каждое 15 и 30 число (в феврале 15 и 28/29)',
            details3: 'При покупке токена GAT с помощью токена AUF бонус составляет 50% и стимулирует рост токена AUF',
            details4: 'При покупке токена GAT с помощью USD вы получите 30% больше токенов за ту же сумму',
            details5: 'За удержание стейка, каждые последующие 30 дней начисляется «Вес монеты» в размере 5%',

        },
        
    }

  },
  mutations: {
    setLang(state, lang) {
      state.lang = lang
    }
  },
  actions: {
    setLang({commit}, lang) {
      commit('setLang', lang)
    }
  }
})