<template>
  <div class="container">

  <div class="firstWindow"  id="head0">
      <div class="firstWindowContant">
          <header>
              <div class="logo">
                  <img src="./assets/logo.png" class="" rel="preload">
                  <div>AUROUS.<br>FINANCE</div>
              </div>
                  <div class="nav">
                      <a href="#head0">{{ $store.state.translation[$store.state.lang].navHome }}</a>
                      <a href="#head1">{{ $store.state.translation[$store.state.lang].navAbout }}</a>
                      <a href="#head2">{{ $store.state.translation[$store.state.lang].navRoadmap }}</a>
                      <a href="#head3">{{ $store.state.translation[$store.state.lang].navToken }}</a>
                      <a href="#head4">{{ $store.state.translation[$store.state.lang].navProg }}</a>
                      <v-select />
                  </div>
                  <div class="login"> 
                      <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="right-to-bracket" class="svg-inline--fa fa-right-to-bracket me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                          <g class="fa-duotone-group">
                              <path class="fa-secondary" fill="currentColor" d="M416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z"></path>
                              <path class="fa-primary" fill="currentColor" d="M344.7 273.5l-144.1 136c-6.975 6.578-17.2 8.375-26 4.594C165.8 410.3 160.1 401.6 160.1 392V320H32.02C14.33 320 0 305.7 0 288V224c0-17.67 14.33-32 32.02-32h128.1V120c0-9.578 5.707-18.25 14.51-22.05c8.803-3.781 19.03-1.984 26 4.594l144.1 136C354.3 247.6 354.3 264.4 344.7 273.5z"></path>
                          </g>
                      </svg>
                      {{ $store.state.translation[$store.state.lang].login }}
                  </div>

              <div class="menuButton" v-on:click="openCloseMenu">
                  <span class="firstMenuBut"></span>
                  <span class="secondMenuBut"></span>
              </div>

              
          </header>

          <div class="firstWindowMain">
              <div class="firstWindowImgPosition">
                  <img src="./assets/1.png" class="firstWindowImg" rel="preload">
                  <div class="firstWindowMainTexts">
                      <div class="firstWindowMainTitle">
                        {{ $store.state.translation[$store.state.lang].firstWindowTitle }}
                      </div>
                      <div class="firstWindowMainText">
                        {{ $store.state.translation[$store.state.lang].firstWindow }}
                      </div>
                  </div>
              </div>
              <div class="firstWindowMainSocial">
                  <!-- <a href="#">
                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_518_33)">
                              <path d="M13.9999 27.9998C21.7318 27.9998 27.9998 21.7318 27.9998 13.9999C27.9998 6.26796 21.7318 0 13.9999 0C6.26796 0 0 6.26796 0 13.9999C0 21.7318 6.26796 27.9998 13.9999 27.9998Z" fill="white" class="activeSocial"/>
                              <path d="M22.5764 10.0614C21.9765 10.3275 21.3311 10.5074 20.6543 10.5878C21.3454 10.1737 21.8757 9.51863 22.126 8.73676C21.4794 9.12033 20.7629 9.39859 20.001 9.54858C19.3905 8.89847 18.5208 8.49219 17.5578 8.49219C15.71 8.49219 14.2114 9.9908 14.2114 11.8386C14.2114 12.1008 14.2411 12.3561 14.2985 12.6012C11.5174 12.4617 9.05147 11.1295 7.40088 9.10436C7.11288 9.59849 6.94768 10.1737 6.94768 10.7869C6.94768 11.9476 7.53888 12.9723 8.43631 13.5723C7.88802 13.555 7.37168 13.4045 6.92072 13.1532C6.92047 13.1675 6.92047 13.1817 6.92047 13.1957C6.92047 14.8171 8.07444 16.1694 9.605 16.4766C9.32449 16.5535 9.02827 16.5942 8.72355 16.5942C8.50743 16.5942 8.29805 16.5735 8.09391 16.5345C8.51966 17.8637 9.75524 18.8313 11.2199 18.8585C10.0744 19.7561 8.63171 20.2909 7.06347 20.2909C6.79395 20.2909 6.52692 20.2752 6.26562 20.244C7.74577 21.1938 9.50518 21.7476 11.3949 21.7476C17.55 21.7476 20.9161 16.6486 20.9161 12.2261C20.9161 12.0811 20.9129 11.9366 20.9064 11.7934C21.5607 11.3217 22.128 10.7325 22.5764 10.0614Z" fill="#1E212D"/>
                          </g>
                          <defs>
                              <clipPath id="clip0_518_33">
                                  <rect width="28" height="28" fill="white"/>
                              </clipPath>
                          </defs>
                      </svg>
                  </a> -->
                  <a href="https://t.me/aurous_finance_official" target="_blank">
                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_518_38)">
                              <path class="activeSocial" d="M14 0C6.2685 0 0 6.2685 0 14C0 21.7315 6.2685 28 14 28C21.7315 28 28 21.7315 28 14C28 6.2685 21.7315 0 14 0ZM20.8763 9.59117L18.578 20.4178C18.4088 21.1855 17.9515 21.3722 17.3133 21.0105L13.8133 18.4322L12.1263 20.0585C11.963 20.2685 11.7098 20.4027 11.4263 20.4027C11.424 20.4027 11.4228 20.4027 11.4205 20.4027L11.669 16.8397L18.1557 10.9807C18.4357 10.7322 18.0927 10.591 17.7205 10.8395L9.70667 15.8865L6.25333 14.8085C5.50667 14.5717 5.48567 14.0618 6.41083 13.6955L19.9045 8.4945C20.5322 8.26583 21.0782 8.64383 20.8752 9.59233L20.8763 9.59117Z" fill="white"/>
                          </g>
                          <defs>
                              <clipPath id="clip0_518_38">
                                  <rect width="28" height="28" fill="white"/>
                              </clipPath>
                          </defs>
                      </svg>
                  </a>
                  <a href="mailto:hello@aurous.finance">
                      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle class="activeSocial" cx="14" cy="14" r="14" fill="white"/>
                          <g clip-path="url(#clip0_518_40)">
                              <path d="M19.8512 8.93555C20.482 8.93555 20.9981 9.45167 20.9981 10.0824V10.4149L19.4047 11.5531L13.98 15.29L8.55521 11.5152V19.0644H8.14691C7.51613 19.0644 7 18.5483 7 17.9176V10.0828C7 9.45197 7.51613 8.93585 8.14691 8.93585L13.98 13.2606L19.8509 8.93585L19.8512 8.93555Z" fill="#1F222D"/>
                              <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint0_linear_518_40)"/>
                              <path d="M8.55469 11.5156V19.0648H19.4045V11.5535L13.9794 15.2904L8.55469 11.5156Z" fill="#E7E4D7"/>
                              <path d="M8.55469 19.0653L13.9794 15.2908L13.9549 15.2734L8.55469 18.9823V19.0653Z" fill="#B8B7AE"/>
                              <path d="M19.4016 11.5527L19.4047 19.064L13.9766 15.2896L19.4016 11.5527Z" fill="#B7B6AD"/>
                              <path d="M20.9994 10.4144L21.0012 17.9179C20.9739 18.7519 20.6125 19.0496 19.4094 19.0636L19.4062 11.5523L20.9997 10.4141L20.9994 10.4144Z" fill="#1F222D"/>
                              <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint1_linear_518_40)"/>
                              <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint2_linear_518_40)"/>
                              <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint3_linear_518_40)"/>
                              <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint4_linear_518_40)"/>
                              <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint5_linear_518_40)"/>
                              <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint6_linear_518_40)"/>
                              <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint7_linear_518_40)"/>
                              <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="#F7F5ED"/>
                          </g>
                          <defs>
                              <linearGradient id="paint0_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#F8F6EF"/>
                                  <stop offset="1" stop-color="#E7E4D6"/>
                              </linearGradient>
                              <linearGradient id="paint1_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#F8F6EF"/>
                                  <stop offset="1" stop-color="#E7E4D6"/>
                              </linearGradient>
                              <linearGradient id="paint2_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#F8F6EF"/>
                                  <stop offset="1" stop-color="#E7E4D6"/>
                              </linearGradient>
                              <linearGradient id="paint3_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#F8F6EF"/>
                                  <stop offset="1" stop-color="#E7E4D6"/>
                              </linearGradient>
                              <linearGradient id="paint4_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#F8F6EF"/>
                                  <stop offset="1" stop-color="#E7E4D6"/>
                              </linearGradient>
                              <linearGradient id="paint5_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#F8F6EF"/>
                                  <stop offset="1" stop-color="#E7E4D6"/>
                              </linearGradient>
                              <linearGradient id="paint6_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#F8F6EF"/>
                                  <stop offset="1" stop-color="#E7E4D6"/>
                              </linearGradient>
                              <linearGradient id="paint7_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                                  <stop stop-color="#F8F6EF"/>
                                  <stop offset="1" stop-color="#E7E4D6"/>
                              </linearGradient>
                              <clipPath id="clip0_518_40">
                                  <rect width="14" height="14" fill="white" transform="translate(7 7)"/>
                              </clipPath>
                          </defs>
                      </svg>
                  </a>
              </div>
          </div>
      </div>

      <img src="./assets/bg1-bottom-1920.png" class="firstWindowBgBottom" rel="preload">

  </div>

  <div class="secondWindow" id="head1">
      <img src="./assets/a-token.png" class="firstAToken" rel="preload">

      <div class="secondWindowWidth"> 
          <div class="secondWindowTitle">
              <span class="titleFirst">{{ $store.state.translation[$store.state.lang].secondWindowTitle1 }}</span>{{ $store.state.translation[$store.state.lang].secondWindowTitle2 }}<span class="titleSecond">{{ $store.state.translation[$store.state.lang].secondWindowTitle3 }}</span>
          </div>

          <div class="advantages">
              <div class='advantagesItem'>
                  
                  <img src="./assets/advantages-icon-1.png" class="" rel="preload">
                  <div class="advantagesItemTitle">
                    {{ $store.state.translation[$store.state.lang].advantagesTitle1 }}
                  </div>
                  <div class="advantagesItemText">
                    {{ $store.state.translation[$store.state.lang].advantagesText1 }}
                  </div>

              </div>
              <div class="advantagesItem">
                  
                  <img src="./assets/advantages-icon-2.png" class="" rel="preload">
                  <div class="advantagesItemTitle">
                    {{ $store.state.translation[$store.state.lang].advantagesTitle2 }}
                  </div>
                  <div class="advantagesItemText">
                    {{ $store.state.translation[$store.state.lang].advantagesText2 }}
                  </div>

              </div>
          </div>

          <div class="experience">
              <img src="./assets/experience-icon.png" class="" rel="preload">
              <div class="experienceContent">
                  <div class="verticalStripe"></div>

                  <div class="experienceItem">
                      <div>
                          2017
                          <div class="horizontalStripe">
                              <div></div>
                          </div>
                      </div>
                      {{ $store.state.translation[$store.state.lang].experience1 }}
                  </div>

                  <div class="experienceItem">
                      <div>
                          2018
                          <div class="horizontalStripe">
                              <div></div>
                          </div>
                      </div>
                      {{ $store.state.translation[$store.state.lang].experience2 }}
                  </div>

                  <div class="experienceItem">
                      <div>
                          2021 
                          <div class="horizontalStripe">
                              <div></div>
                          </div>
                      </div>
                      {{ $store.state.translation[$store.state.lang].experience3 }}
                  </div>
              </div>
          </div>
      </div>

      <svg width="1920" height="255" viewBox="0 0 1920 255" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g filter="url(#filter0_dd_518_85)">
              <path d="M-12 237C346.743 35.4798 1084.77 127.261 1270.66 120.777C1456.54 114.292 1700.04 108.306 1920 27" stroke="#FFB800" stroke-width="3"/>
          </g>
          <defs>
              <filter id="filter0_dd_518_85" x="-32.7344" y="0.59375" width="1973.26" height="253.715" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="6"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.92549 0 0 0 0 0.709804 0 0 0 0 0.211765 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_518_85"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-5"/>
                  <feGaussianBlur stdDeviation="10"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.690196 0 0 0 0 0.188235 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="effect1_dropShadow_518_85" result="effect2_dropShadow_518_85"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_518_85" result="shape"/>
              </filter>
          </defs>
      </svg>

  </div>

  <div class="thirdWindow" id="head2">

      <img src="./assets/a-token.png" class="secondAToken" rel="preload">
      <img src="./assets/b-token.png" class="firstBToken" rel="preload">
      <img src="./assets/b-token.png" class="secondBToken" rel="preload">

      <img src="./assets/roadmap-bg.png" class="roadmapBg" rel="preload">
      <img src="./assets/roadmap-bg-2.png" class="roadmapBg2" rel="preload">

      <div class="thirdWindowWidth">

          <div class="roadmapDescription">
              <span>{{ $store.state.translation[$store.state.lang].roadmap }}</span>
              <div class="dottetLine"></div>
          </div>

          <div class="roadmapTitle">
              <span class="firstRoadmapTitle">{{ $store.state.translation[$store.state.lang].thirdTitle1 }}</span>{{ $store.state.translation[$store.state.lang].thirdTitle2 }}<span class="secondRoadmapTitle">{{ $store.state.translation[$store.state.lang].thirdTitle3 }}</span>
          </div>

          <div class="dottetLineHoriz"></div>

          <div class="roadmapMain">
              <div class="roadmapMainItem">
                  <img src="./assets/roadmap-1.png" class="" rel="preload">
                  <div class="roadmapMainItemText r1">
                    {{ $store.state.translation[$store.state.lang].roadmapItemTitle1 }}
                      <div>
                        {{ $store.state.translation[$store.state.lang].roadmapItem1 }}
                      </div>
                  </div>
              </div>

              <div class="dottetLineVertical"></div>
              <div class="dottetLineHoriz for1000"></div>

              <div class="roadmapMainItem">
                  <img src="./assets/roadmap-2.png" class="" rel="preload">
                  <div class="roadmapMainItemText r2">
                    {{ $store.state.translation[$store.state.lang].roadmapItemTitle2 }}
                      <div>
                        {{ $store.state.translation[$store.state.lang].roadmapItem2 }}
                      </div>
                  </div>
              </div>

              <div class="dottetLineHoriz"></div>
              
              <div class="roadmapMainItem">
                  <img src="./assets/roadmap-3.png" class="" rel="preload">
                  <div class="roadmapMainItemText r3">
                      <span class="r31">{{ $store.state.translation[$store.state.lang].roadmapItemTitle31 }}</span>
                      <span class="r32">{{ $store.state.translation[$store.state.lang].roadmapItemTitle32 }}</span>
                      <div>
                        {{ $store.state.translation[$store.state.lang].roadmapItem3 }}
                      </div>
                  </div>
              </div>

              <div class="dottetLineVertical"></div>
              <div class="dottetLineHoriz for1000"></div>

              <div class="roadmapMainItem">
                  <img src="./assets/roadmap-4.png" class="" rel="preload">
                  <div class="roadmapMainItemText r4">
                    {{ $store.state.translation[$store.state.lang].roadmapItemTitle4 }}
                      <div>
                        {{ $store.state.translation[$store.state.lang].roadmapItem4 }}
                      </div>
                  </div>
              </div>
          </div>

          <div class="dottetLineHoriz" style="background-position: bottom;"></div>
          
      </div>

      <img src="./assets/bg2-bottom-1920.png" class="bg2Bottom" rel="preload">
  </div>

  <div class="fourthWindow" id="head3">

      <img src="./assets/a-token.png" class="thirdAToken" rel="preload">
      <img src="./assets/b-token.png" class="thirdBToken" rel="preload">
      <img src="./assets/b-token.png" class="fourthBToken" rel="preload">
      <img src="./assets/a-token.png" class="fourthAToken" rel="preload">
      
      <div class="fourthWindowWidth">
          <div class="fourthWindowTitle">
              <span class="fourthWindowTitle1">{{ $store.state.translation[$store.state.lang].fourthTitle1 }}</span>{{ $store.state.translation[$store.state.lang].fourthTitle2 }}<span class="fourthWindowTitle2">{{ $store.state.translation[$store.state.lang].fourthTitle3 }}</span>
          </div>

          <div class="tokens">
              <div class="token">

                  <div class="dottetLineHorizToken"></div>
                  <div class="dottetLineVerticalToken"></div>

                  <div class="tokenInfo">
                      <div class="tokenInfoName">
                          <img src="./assets/token-a.svg" class="" rel="preload">
                          <span>{{ $store.state.translation[$store.state.lang].tokenAufTitle }}</span>
                      </div>

                      <div class="tokenInfoTitle">
                          <span>{{ $store.state.translation[$store.state.lang].tokenPrice }}</span>
                          <div>{{ $store.state.translation[$store.state.lang].tokenAufPrice }}</div>
                      </div>

                      <div class="tokenInfoTitle">
                          <span>{{ $store.state.translation[$store.state.lang].tokenIncome }}</span>
                          <div>0.24%/{{ $store.state.translation[$store.state.lang].tokenDay }}</div>
                      </div>

                      <div class="tokenInfoText">
                        {{ $store.state.translation[$store.state.lang].tokenAuf }}
                      </div>
                  </div>

                  <div class="dottetLineVerticalToken"></div>
                  <div class="dottetLineHorizToken"></div>

              </div>

              <div class="token">

                  <div class="dottetLineHorizToken"></div>
                  <div class="dottetLineVerticalToken"></div>

                  <div class="tokenInfo">
                      <div class="tokenInfoName">
                          <img src="./assets/token-g.svg" class="" rel="preload">
                          <span>{{ $store.state.translation[$store.state.lang].tokenGatTitle }}</span>
                      </div>

                      <div class="tokenInfoTitle">
                          <span>{{ $store.state.translation[$store.state.lang].tokenPrice }}</span>
                          <div>{{ $store.state.translation[$store.state.lang].tokenGatPrice }}</div>
                      </div>

                      <div class="tokenInfoTitle">
                          <span>{{ $store.state.translation[$store.state.lang].tokenIncome }}</span>
                          <div>0.5%/{{ $store.state.translation[$store.state.lang].tokenDay }}</div>
                      </div>

                      <div class="tokenInfoText">
                        {{ $store.state.translation[$store.state.lang].tokenGat }}
                      </div>
                  </div>

                  <div class="dottetLineVerticalToken"></div>
                  <div class="dottetLineHorizToken"></div>

              </div>
          </div>

          <div class="strategies">
              <div class="strategieTitle">
                {{ $store.state.translation[$store.state.lang].strategy }} 1
              </div>

              <div class="strategieMain">

                  <div class="dottetLineHorizToken"></div>
                  <div class="dottetLineVerticalToken"></div>

                  <div class="strategieItems">

                      <div class="strategieItem">
                          <div>{{ $store.state.translation[$store.state.lang].stBuy }}</div>
                          <img src="./assets/token-a.svg" class="" rel="preload">
                          <div>{{ $store.state.translation[$store.state.lang].stFor }} $0.1 usd</div>
                      </div>

                      <div class="verticalDivider"></div>
                      <div class="strategieItemHoriz1"></div>

                      <div class="strategieItem">
                          <div>0.24%/{{ $store.state.translation[$store.state.lang].stDay }}</div>
                          <div class="strategieItemInfo">

                              <div class="numberStrategie"><span>1</span></div>

                              <div class="strategieItemInfoIconText">
                                  <img src="./assets/token-a.svg" class="" rel="preload">
                                  <span>+2.5%*</span>
                              </div>

                              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-on:click="openInfo">
                                  <circle cx="11.498" cy="11.498" r="10.3261" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4974 0.0390625C5.16914 0.0390625 0.0390625 5.16914 0.0390625 11.4974C0.0390625 17.8256 5.16914 22.9557 11.4974 22.9557C17.8256 22.9557 22.9557 17.8256 22.9557 11.4974C22.9557 5.16914 17.8256 0.0390625 11.4974 0.0390625ZM10.9766 5.2474C10.4013 5.2474 9.9349 5.71377 9.9349 6.28906C9.9349 6.86435 10.4013 7.33073 10.9766 7.33073H11.4974C12.0727 7.33073 12.5391 6.86435 12.5391 6.28906C12.5391 5.71377 12.0727 5.2474 11.4974 5.2474H10.9766ZM9.41406 9.41406C8.83876 9.41406 8.3724 9.88042 8.3724 10.4557C8.3724 11.031 8.83876 11.4974 9.41406 11.4974H10.4557V14.6224H9.41406C8.83876 14.6224 8.3724 15.0888 8.3724 15.6641C8.3724 16.2394 8.83876 16.7057 9.41406 16.7057H13.5807C14.156 16.7057 14.6224 16.2394 14.6224 15.6641C14.6224 15.0888 14.156 14.6224 13.5807 14.6224H12.5391V10.4557C12.5391 9.88042 12.0727 9.41406 11.4974 9.41406H9.41406Z" fill="#383636"/>
                              </svg>  
                                  
                          </div>
                          <div>{{ $store.state.translation[$store.state.lang].stBIncome }}</div>
                      </div>

                      <div class="verticalDivider centerVert"></div>
                      <div class="strategieItemHoriz1"></div>

                      <div class="strategieItem">
                          <div class="strategieItemTitle">
                              <span>{{ $store.state.translation[$store.state.lang].stFor }}</span>
                              <img src="./assets/token-a.svg" class="forTitle" rel="preload">
                              <span>{{ $store.state.translation[$store.state.lang].stBuy }}</span>
                          </div>
                          <div class="strategieItemInfo">

                              <div class="numberStrategie"><span>2</span></div>

                              <div class="strategieItemInfoIconText">
                                  <img src="./assets/token-g.svg" class="" rel="preload">
                                  <span>+50%*</span>
                                  <span class="strategieLineThrough">+30%*</span>
                              </div>

                              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"  v-on:click="openInfo">
                                  <circle cx="11.498" cy="11.498" r="10.3261" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4974 0.0390625C5.16914 0.0390625 0.0390625 5.16914 0.0390625 11.4974C0.0390625 17.8256 5.16914 22.9557 11.4974 22.9557C17.8256 22.9557 22.9557 17.8256 22.9557 11.4974C22.9557 5.16914 17.8256 0.0390625 11.4974 0.0390625ZM10.9766 5.2474C10.4013 5.2474 9.9349 5.71377 9.9349 6.28906C9.9349 6.86435 10.4013 7.33073 10.9766 7.33073H11.4974C12.0727 7.33073 12.5391 6.86435 12.5391 6.28906C12.5391 5.71377 12.0727 5.2474 11.4974 5.2474H10.9766ZM9.41406 9.41406C8.83876 9.41406 8.3724 9.88042 8.3724 10.4557C8.3724 11.031 8.83876 11.4974 9.41406 11.4974H10.4557V14.6224H9.41406C8.83876 14.6224 8.3724 15.0888 8.3724 15.6641C8.3724 16.2394 8.83876 16.7057 9.41406 16.7057H13.5807C14.156 16.7057 14.6224 16.2394 14.6224 15.6641C14.6224 15.0888 14.156 14.6224 13.5807 14.6224H12.5391V10.4557C12.5391 9.88042 12.0727 9.41406 11.4974 9.41406H9.41406Z" fill="#383636"/>
                              </svg>  
                                  
                          </div>
                          <div>50% {{ $store.state.translation[$store.state.lang].stDiscount }}</div>
                      </div>

                      <div class="verticalDivider"></div>
                      <div class="strategieItemHoriz1"></div>

                      <div class="strategieItem">
                          <div class="strategieItemTitle">
                              <span>0.5%/{{ $store.state.translation[$store.state.lang].stDay }}</span>
                          </div>
                          <div class="strategieItemInfo">

                              <div class="numberStrategie"><span>3</span></div>

                              <div class="strategieItemInfoIconText">
                                  <img src="./assets/token-g.svg" class="" rel="preload">
                                  <span>+0.26%</span>
                              </div>

                              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"  v-on:click="openInfo">
                                  <circle cx="11.498" cy="11.498" r="10.3261" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4974 0.0390625C5.16914 0.0390625 0.0390625 5.16914 0.0390625 11.4974C0.0390625 17.8256 5.16914 22.9557 11.4974 22.9557C17.8256 22.9557 22.9557 17.8256 22.9557 11.4974C22.9557 5.16914 17.8256 0.0390625 11.4974 0.0390625ZM10.9766 5.2474C10.4013 5.2474 9.9349 5.71377 9.9349 6.28906C9.9349 6.86435 10.4013 7.33073 10.9766 7.33073H11.4974C12.0727 7.33073 12.5391 6.86435 12.5391 6.28906C12.5391 5.71377 12.0727 5.2474 11.4974 5.2474H10.9766ZM9.41406 9.41406C8.83876 9.41406 8.3724 9.88042 8.3724 10.4557C8.3724 11.031 8.83876 11.4974 9.41406 11.4974H10.4557V14.6224H9.41406C8.83876 14.6224 8.3724 15.0888 8.3724 15.6641C8.3724 16.2394 8.83876 16.7057 9.41406 16.7057H13.5807C14.156 16.7057 14.6224 16.2394 14.6224 15.6641C14.6224 15.0888 14.156 14.6224 13.5807 14.6224H12.5391V10.4557C12.5391 9.88042 12.0727 9.41406 11.4974 9.41406H9.41406Z" fill="#383636"/>
                              </svg>  
                                  
                          </div>
                          <div>{{ $store.state.translation[$store.state.lang].stMIncome }}</div>
                      </div>
                      
                  </div>

                  <div class="dottetLineVerticalToken"></div>
                  <div class="dottetLineHorizToken"></div>

              </div>
          </div>

          <div class="strategies">
              <div class="strategieTitle">
                {{ $store.state.translation[$store.state.lang].strategy }} 2
              </div>

              <div class="strategieMain">

                  <div class="dottetLineHorizToken"></div>
                  <div class="dottetLineVerticalToken"></div>

                  <div class="strategieItems secondStrateg">

                      <div class="strategieItem secondStrat">
                          <div>{{ $store.state.translation[$store.state.lang].stBuy }}</div>
                          <img src="./assets/token-g.svg" class="" rel="preload">
                          <div>{{ $store.state.translation[$store.state.lang].stDis1 }}</div>
                      </div>

                      <div class="verticalDivider secondStrat"></div>
                      <div class="strategieItemHoriz"></div>

                      <div class="strategieItem secondStrat">
                          <div>{{ $store.state.translation[$store.state.lang].stGet }}</div>
                          <div class="strategieItemInfo">

                              <div class="numberStrategie"><span>4</span></div>

                              <div class="strategieItemInfoIconText">
                                  <img src="./assets/token-g.svg" class="" rel="preload">
                                  <span>+30%</span>
                              </div>

                              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"  v-on:click="openInfo">
                                  <circle cx="11.498" cy="11.498" r="10.3261" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4974 0.0390625C5.16914 0.0390625 0.0390625 5.16914 0.0390625 11.4974C0.0390625 17.8256 5.16914 22.9557 11.4974 22.9557C17.8256 22.9557 22.9557 17.8256 22.9557 11.4974C22.9557 5.16914 17.8256 0.0390625 11.4974 0.0390625ZM10.9766 5.2474C10.4013 5.2474 9.9349 5.71377 9.9349 6.28906C9.9349 6.86435 10.4013 7.33073 10.9766 7.33073H11.4974C12.0727 7.33073 12.5391 6.86435 12.5391 6.28906C12.5391 5.71377 12.0727 5.2474 11.4974 5.2474H10.9766ZM9.41406 9.41406C8.83876 9.41406 8.3724 9.88042 8.3724 10.4557C8.3724 11.031 8.83876 11.4974 9.41406 11.4974H10.4557V14.6224H9.41406C8.83876 14.6224 8.3724 15.0888 8.3724 15.6641C8.3724 16.2394 8.83876 16.7057 9.41406 16.7057H13.5807C14.156 16.7057 14.6224 16.2394 14.6224 15.6641C14.6224 15.0888 14.156 14.6224 13.5807 14.6224H12.5391V10.4557C12.5391 9.88042 12.0727 9.41406 11.4974 9.41406H9.41406Z" fill="#383636"/>
                              </svg>  
                                  
                          </div>
                          <div>{{ $store.state.translation[$store.state.lang].stDis2 }}</div>
                      </div>

                      <div class="verticalDivider secondStrat"></div>
                      <div class="strategieItemHoriz"></div>

                      <div class="strategieItem secondStrat">
                          <div>{{ $store.state.translation[$store.state.lang].stIncome }}/{{ $store.state.translation[$store.state.lang].stDay }}</div>
                          <div class="strategieItemInfo">

                              <div class="numberStrategie"><span>5</span></div>

                              <div class="strategieItemInfoIconText">
                                  <img src="./assets/token-g.svg" class="" rel="preload">
                              </div>

                              <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" v-on:click="openInfo">
                                  <circle cx="11.498" cy="11.498" r="10.3261" fill="white"/>
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.4974 0.0390625C5.16914 0.0390625 0.0390625 5.16914 0.0390625 11.4974C0.0390625 17.8256 5.16914 22.9557 11.4974 22.9557C17.8256 22.9557 22.9557 17.8256 22.9557 11.4974C22.9557 5.16914 17.8256 0.0390625 11.4974 0.0390625ZM10.9766 5.2474C10.4013 5.2474 9.9349 5.71377 9.9349 6.28906C9.9349 6.86435 10.4013 7.33073 10.9766 7.33073H11.4974C12.0727 7.33073 12.5391 6.86435 12.5391 6.28906C12.5391 5.71377 12.0727 5.2474 11.4974 5.2474H10.9766ZM9.41406 9.41406C8.83876 9.41406 8.3724 9.88042 8.3724 10.4557C8.3724 11.031 8.83876 11.4974 9.41406 11.4974H10.4557V14.6224H9.41406C8.83876 14.6224 8.3724 15.0888 8.3724 15.6641C8.3724 16.2394 8.83876 16.7057 9.41406 16.7057H13.5807C14.156 16.7057 14.6224 16.2394 14.6224 15.6641C14.6224 15.0888 14.156 14.6224 13.5807 14.6224H12.5391V10.4557C12.5391 9.88042 12.0727 9.41406 11.4974 9.41406H9.41406Z" fill="#383636"/>
                              </svg>                                            

                          </div>
                          <div>0.5%/{{ $store.state.translation[$store.state.lang].stDay }}</div>
                      </div>
                      
                  </div>

                  <div class="dottetLineVerticalToken"></div>
                  <div class="dottetLineHorizToken"></div>

              </div>
          </div>
      </div>

      <svg width="1920" height="255" viewBox="0 0 1920 255" fill="none" xmlns="http://www.w3.org/2000/svg" class="fourthWindowSvg">
          <g filter="url(#filter0_dd_552_5)">
              <path d="M-13 237C76.242 149.288 169.973 32.9562 439.671 109.289C709.369 185.622 972.549 4.55344 1270.32 120.777C1568.09 237 1699.93 108.306 1920 27" stroke="#FFB800" stroke-width="3"/>
          </g>
          <defs>
              <filter id="filter0_dd_552_5" x="-34.0508" y="0.59375" width="1974.57" height="253.477" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                  <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="4"/>
                  <feGaussianBlur stdDeviation="6"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.92549 0 0 0 0 0.709804 0 0 0 0 0.211765 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_552_5"/>
                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                  <feOffset dy="-5"/>
                  <feGaussianBlur stdDeviation="10"/>
                  <feComposite in2="hardAlpha" operator="out"/>
                  <feColorMatrix type="matrix" values="0 0 0 0 0.913725 0 0 0 0 0.690196 0 0 0 0 0.188235 0 0 0 1 0"/>
                  <feBlend mode="normal" in2="effect1_dropShadow_552_5" result="effect2_dropShadow_552_5"/>
                  <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_552_5" result="shape"/>
              </filter>
          </defs>

      </svg>
          
  </div>

  <div class="fifthWindow" id="head4">
      <img src="./assets/partnership.png" class="partnershipTextImgSome" rel="preload">

      <div class="fifthWindowWidth">

          <div class="dottetLineHoriz fiftW"></div>

          <div class="fifthWindowTitle">
            {{ $store.state.translation[$store.state.lang].affiliate1 }}<span>{{ $store.state.translation[$store.state.lang].affiliate2 }}</span>
          </div>

          <div class="fifthWindowText">
            {{ $store.state.translation[$store.state.lang].affiliateText1 }}
          </div>

          <div class="partnership">
              <div class="partnershipText">
                  <div class="partnershipLevels">
                      <span>1 {{ $store.state.translation[$store.state.lang].level }} – 5%</span>
                      <span>2 {{ $store.state.translation[$store.state.lang].level }} – 2.5%</span>
                      <span>3 {{ $store.state.translation[$store.state.lang].level }} – 1%</span>
                      <span>4 {{ $store.state.translation[$store.state.lang].level }} – 1%</span>
                      <span>5 {{ $store.state.translation[$store.state.lang].level }} – 1%</span>
                      <span>6 {{ $store.state.translation[$store.state.lang].level }} – 1%</span>
                      <span>7 {{ $store.state.translation[$store.state.lang].level }} – 1%</span>
                      <span>8 {{ $store.state.translation[$store.state.lang].level }} – 1%</span>
                      <span>9 {{ $store.state.translation[$store.state.lang].level }} – 1%</span>
                      <span>10 {{ $store.state.translation[$store.state.lang].level }} – 0.5%</span>
                  </div>
                  <span>
                    {{ $store.state.translation[$store.state.lang].affiliateText2 }}
                  </span>
              </div>

              <img src="./assets/partnership.png" class="" rel="preload">
          </div>

          <div class="dottetLineHoriz fiftW"></div>

      </div>

  </div>

  <footer>
      <header class="footerHeader">
          <div class="logo">
              <img src="./assets/logo.png" class="" rel="preload">
              <div>AUROUS.<br>FINANCE</div>
          </div>
          <div class="nav">
            <a href="#head0">{{ $store.state.translation[$store.state.lang].navHome }}</a>
            <a href="#head1">{{ $store.state.translation[$store.state.lang].navAbout }}</a>
            <a href="#head2">{{ $store.state.translation[$store.state.lang].navRoadmap }}</a>
            <a href="#head3">{{ $store.state.translation[$store.state.lang].navToken }}</a>
            <a href="#head4">{{ $store.state.translation[$store.state.lang].navProg }}</a>
            <v-select />
          </div>
          <div class="login">
            <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="right-to-bracket" class="svg-inline--fa fa-right-to-bracket me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <g class="fa-duotone-group">
                    <path class="fa-secondary" fill="currentColor" d="M416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z"></path>
                    <path class="fa-primary" fill="currentColor" d="M344.7 273.5l-144.1 136c-6.975 6.578-17.2 8.375-26 4.594C165.8 410.3 160.1 401.6 160.1 392V320H32.02C14.33 320 0 305.7 0 288V224c0-17.67 14.33-32 32.02-32h128.1V120c0-9.578 5.707-18.25 14.51-22.05c8.803-3.781 19.03-1.984 26 4.594l144.1 136C354.3 247.6 354.3 264.4 344.7 273.5z"></path>
                </g>
            </svg>
            {{ $store.state.translation[$store.state.lang].login }}
          </div>
      </header>

      <div class="footerSocial">
          <!-- <div class="footerSocialItem">
              <span>FOR INVESTORS</span>
              <a href="#">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle class="activeSocial" cx="14" cy="14" r="14" fill="white"/>
                  <g clip-path="url(#clip0_518_190)">
                  <path d="M19.8512 8.93555C20.482 8.93555 20.9981 9.45167 20.9981 10.0824V10.4149L19.4047 11.5531L13.98 15.29L8.55521 11.5152V19.0644H8.14691C7.51613 19.0644 7 18.5483 7 17.9176V10.0828C7 9.45197 7.51613 8.93585 8.14691 8.93585L13.98 13.2606L19.8509 8.93585L19.8512 8.93555Z" fill="#E7A909"/>
                  <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint0_linear_518_190)"/>
                  <path d="M8.55469 11.5156V19.0648H19.4045V11.5535L13.9794 15.2904L8.55469 11.5156Z" fill="#E7E4D7"/>
                  <path d="M8.55469 19.0653L13.9794 15.2908L13.9549 15.2734L8.55469 18.9823V19.0653Z" fill="#B8B7AE"/>
                  <path d="M19.4016 11.5527L19.4047 19.064L13.9766 15.2896L19.4016 11.5527Z" fill="#B7B6AD"/>
                  <path d="M20.9994 10.4144L21.0012 17.9179C20.9739 18.7519 20.6125 19.0496 19.4094 19.0636L19.4062 11.5523L20.9997 10.4141L20.9994 10.4144Z" fill="#E6A809"/>
                  <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint1_linear_518_190)"/>
                  <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint2_linear_518_190)"/>
                  <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint3_linear_518_190)"/>
                  <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint4_linear_518_190)"/>
                  <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint5_linear_518_190)"/>
                  <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint6_linear_518_190)"/>
                  <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint7_linear_518_190)"/>
                  <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="#F7F5ED"/>
                  </g>
                  <defs>
                  <linearGradient id="paint0_linear_518_190" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFCB15"/>
                  <stop offset="1" stop-color="white"/>
                  </linearGradient>
                  <linearGradient id="paint1_linear_518_190" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFCB15"/>
                  <stop offset="1" stop-color="white"/>
                  </linearGradient>
                  <linearGradient id="paint2_linear_518_190" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFCB15"/>
                  <stop offset="1" stop-color="white"/>
                  </linearGradient>
                  <linearGradient id="paint3_linear_518_190" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFCB15"/>
                  <stop offset="1" stop-color="white"/>
                  </linearGradient>
                  <linearGradient id="paint4_linear_518_190" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFCB15"/>
                  <stop offset="1" stop-color="white"/>
                  </linearGradient>
                  <linearGradient id="paint5_linear_518_190" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFCB15"/>
                  <stop offset="1" stop-color="white"/>
                  </linearGradient>
                  <linearGradient id="paint6_linear_518_190" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFCB15"/>
                  <stop offset="1" stop-color="white"/>
                  </linearGradient>
                  <linearGradient id="paint7_linear_518_190" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                  <stop stop-color="#FFCB15"/>
                  <stop offset="1" stop-color="white"/>
                  </linearGradient>
                  <clipPath id="clip0_518_190">
                  <rect width="14" height="14" fill="white" transform="translate(7 7)"/>
                  </clipPath>
                  </defs>
                  </svg>
              </a>
              
              <a href="#">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="activeSocial" d="M14 0C6.2685 0 0 6.2685 0 14C0 21.7315 6.2685 28 14 28C21.7315 28 28 21.7315 28 14C28 6.2685 21.7315 0 14 0ZM20.8763 9.59117L18.578 20.4178C18.4088 21.1855 17.9515 21.3722 17.3133 21.0105L13.8133 18.4322L12.1263 20.0585C11.963 20.2685 11.7098 20.4027 11.4263 20.4027C11.424 20.4027 11.4228 20.4027 11.4205 20.4027L11.669 16.8397L18.1557 10.9807C18.4357 10.7322 18.0927 10.591 17.7205 10.8395L9.70667 15.8865L6.25333 14.8085C5.50667 14.5717 5.48567 14.0618 6.41083 13.6955L19.9045 8.4945C20.5322 8.26583 21.0782 8.64383 20.8752 9.59233L20.8763 9.59117Z" fill="white"/>
                  </svg>
              </a>
              
                  
          </div> -->

          <div class="footerSocialItem">
              <span>Social media</span>

              <!-- <a href="#">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="activeSocial" d="M13.9999 27.9998C21.7318 27.9998 27.9998 21.7318 27.9998 13.9999C27.9998 6.26796 21.7318 0 13.9999 0C6.26796 0 0 6.26796 0 13.9999C0 21.7318 6.26796 27.9998 13.9999 27.9998Z" fill="white"/>
                  <path d="M22.5764 10.0614C21.9765 10.3275 21.3311 10.5074 20.6543 10.5878C21.3454 10.1737 21.8757 9.51863 22.126 8.73676C21.4794 9.12033 20.7629 9.39859 20.001 9.54858C19.3905 8.89847 18.5208 8.49219 17.5578 8.49219C15.71 8.49219 14.2114 9.9908 14.2114 11.8386C14.2114 12.1008 14.2411 12.3561 14.2985 12.6012C11.5174 12.4617 9.05147 11.1295 7.40088 9.10436C7.11288 9.59849 6.94768 10.1737 6.94768 10.7869C6.94768 11.9476 7.53888 12.9723 8.43631 13.5723C7.88802 13.555 7.37168 13.4045 6.92072 13.1532C6.92047 13.1675 6.92047 13.1817 6.92047 13.1957C6.92047 14.8171 8.07444 16.1694 9.605 16.4766C9.32449 16.5535 9.02827 16.5942 8.72355 16.5942C8.50743 16.5942 8.29805 16.5735 8.09391 16.5345C8.51966 17.8637 9.75524 18.8313 11.2199 18.8585C10.0744 19.7561 8.63171 20.2909 7.06347 20.2909C6.79395 20.2909 6.52692 20.2752 6.26562 20.244C7.74577 21.1938 9.50518 21.7476 11.3949 21.7476C17.55 21.7476 20.9161 16.6486 20.9161 12.2261C20.9161 12.0811 20.9129 11.9366 20.9064 11.7934C21.5607 11.3217 22.128 10.7325 22.5764 10.0614Z" fill="#E1A408"/>
                  </svg>
              </a> -->
              
              <a href="https://t.me/aurous_finance_official">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path class="activeSocial" d="M14 0C6.2685 0 0 6.2685 0 14C0 21.7315 6.2685 28 14 28C21.7315 28 28 21.7315 28 14C28 6.2685 21.7315 0 14 0ZM20.8763 9.59117L18.578 20.4178C18.4088 21.1855 17.9515 21.3722 17.3133 21.0105L13.8133 18.4322L12.1263 20.0585C11.963 20.2685 11.7098 20.4027 11.4263 20.4027C11.424 20.4027 11.4228 20.4027 11.4205 20.4027L11.669 16.8397L18.1557 10.9807C18.4357 10.7322 18.0927 10.591 17.7205 10.8395L9.70667 15.8865L6.25333 14.8085C5.50667 14.5717 5.48567 14.0618 6.41083 13.6955L19.9045 8.4945C20.5322 8.26583 21.0782 8.64383 20.8752 9.59233L20.8763 9.59117Z" fill="white"/>
                  </svg>
              </a>

              <a href="mailto:hello@aurous.finance">
                  <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle class="activeSocial" cx="14" cy="14" r="14" fill="white"/>
                      <g clip-path="url(#clip0_518_40)">
                          <path d="M19.8512 8.93555C20.482 8.93555 20.9981 9.45167 20.9981 10.0824V10.4149L19.4047 11.5531L13.98 15.29L8.55521 11.5152V19.0644H8.14691C7.51613 19.0644 7 18.5483 7 17.9176V10.0828C7 9.45197 7.51613 8.93585 8.14691 8.93585L13.98 13.2606L19.8509 8.93585L19.8512 8.93555Z" fill="#1F222D"/>
                          <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint0_linear_518_40)"/>
                          <path d="M8.55469 11.5156V19.0648H19.4045V11.5535L13.9794 15.2904L8.55469 11.5156Z" fill="#E7E4D7"/>
                          <path d="M8.55469 19.0653L13.9794 15.2908L13.9549 15.2734L8.55469 18.9823V19.0653Z" fill="#B8B7AE"/>
                          <path d="M19.4016 11.5527L19.4047 19.064L13.9766 15.2896L19.4016 11.5527Z" fill="#B7B6AD"/>
                          <path d="M20.9994 10.4144L21.0012 17.9179C20.9739 18.7519 20.6125 19.0496 19.4094 19.0636L19.4062 11.5523L20.9997 10.4141L20.9994 10.4144Z" fill="#1F222D"/>
                          <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint1_linear_518_40)"/>
                          <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint2_linear_518_40)"/>
                          <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint3_linear_518_40)"/>
                          <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint4_linear_518_40)"/>
                          <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint5_linear_518_40)"/>
                          <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint6_linear_518_40)"/>
                          <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="url(#paint7_linear_518_40)"/>
                          <path d="M8.14844 8.93555L13.9815 13.2603L19.8528 8.93555H8.14844Z" fill="#F7F5ED"/>
                      </g>
                      <defs>
                          <linearGradient id="paint0_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F8F6EF"/>
                              <stop offset="1" stop-color="#E7E4D6"/>
                          </linearGradient>
                          <linearGradient id="paint1_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F8F6EF"/>
                              <stop offset="1" stop-color="#E7E4D6"/>
                          </linearGradient>
                          <linearGradient id="paint2_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F8F6EF"/>
                              <stop offset="1" stop-color="#E7E4D6"/>
                          </linearGradient>
                          <linearGradient id="paint3_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F8F6EF"/>
                              <stop offset="1" stop-color="#E7E4D6"/>
                          </linearGradient>
                          <linearGradient id="paint4_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F8F6EF"/>
                              <stop offset="1" stop-color="#E7E4D6"/>
                          </linearGradient>
                          <linearGradient id="paint5_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F8F6EF"/>
                              <stop offset="1" stop-color="#E7E4D6"/>
                          </linearGradient>
                          <linearGradient id="paint6_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F8F6EF"/>
                              <stop offset="1" stop-color="#E7E4D6"/>
                          </linearGradient>
                          <linearGradient id="paint7_linear_518_40" x1="10.2543" y1="11.0983" x2="17.7327" y2="11.0983" gradientUnits="userSpaceOnUse">
                              <stop stop-color="#F8F6EF"/>
                              <stop offset="1" stop-color="#E7E4D6"/>
                          </linearGradient>
                          <clipPath id="clip0_518_40">
                              <rect width="14" height="14" fill="white" transform="translate(7 7)"/>
                          </clipPath>
                      </defs>
                  </svg>
              </a>

          </div>
      </div>

      <div class="footerRights">
          <div>
              All rights reversed ©
          </div>
          <span>
              2022-2023
          </span>
      </div>
      
  </footer>

  <div id="menu" class="menuWindow">
      <div class="nav nav-active">
        <a href="#head0" v-on:click="openCloseMenu">{{ $store.state.translation[$store.state.lang].navHome }}</a>
        <a href="#head1" v-on:click="openCloseMenu">{{ $store.state.translation[$store.state.lang].navAbout }}</a>
        <a href="#head2" v-on:click="openCloseMenu">{{ $store.state.translation[$store.state.lang].navRoadmap }}</a>
        <a href="#head3" v-on:click="openCloseMenu">{{ $store.state.translation[$store.state.lang].navToken }}</a>
        <a href="#head4" v-on:click="openCloseMenu">{{ $store.state.translation[$store.state.lang].navProg }}</a>
        <v-select />

          <div class="login" v-on:click="openCloseMenu" style="display: flex"> 
              <svg aria-hidden="true" focusable="false" data-prefix="fad" data-icon="right-to-bracket" class="svg-inline--fa fa-right-to-bracket me-2" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <g class="fa-duotone-group">
                      <path class="fa-secondary" fill="currentColor" d="M416 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c17.67 0 32 14.33 32 32v256c0 17.67-14.33 32-32 32h-64c-17.67 0-32 14.33-32 32s14.33 32 32 32h64c53.02 0 96-42.98 96-96V128C512 74.98 469 32 416 32z"></path>
                      <path class="fa-primary" fill="currentColor" d="M344.7 273.5l-144.1 136c-6.975 6.578-17.2 8.375-26 4.594C165.8 410.3 160.1 401.6 160.1 392V320H32.02C14.33 320 0 305.7 0 288V224c0-17.67 14.33-32 32.02-32h128.1V120c0-9.578 5.707-18.25 14.51-22.05c8.803-3.781 19.03-1.984 26 4.594l144.1 136C354.3 247.6 354.3 264.4 344.7 273.5z"></path>
                  </g>
              </svg>
              {{ $store.state.translation[$store.state.lang].login }}
          </div>
          <!-- <a href="#" v-on:click="openCloseMenu()">ВОЙТИ</a> -->
      </div>
  </div>

  </div>

  <div id="info" class="infoWindow">
  <div class="infoWindowContent">
      <div class="infoWindowContentTitle">
        {{ $store.state.translation[$store.state.lang].detailsTitle }}
      </div>
      <div class="infoWindowContentItems">
          <div class="infoWindowContentItem">
              <div class="numberStrategie numberStrategieForInfo"><span>1</span></div>
              <div class="infoWindowContentItemText">
                {{ $store.state.translation[$store.state.lang].details1 }}
              </div>
          </div>

          <div class="infoWindowContentItem">
              <div class="numberStrategie numberStrategieForInfo"><span>2</span></div>
              <div class="infoWindowContentItemText">
                {{ $store.state.translation[$store.state.lang].details2 }}
              </div>
          </div>

          <div class="infoWindowContentItem">
              <div class="numberStrategie numberStrategieForInfo"><span>3</span></div>
              <div class="infoWindowContentItemText">
                {{ $store.state.translation[$store.state.lang].details3 }}
              </div>
          </div>

          <div class="infoWindowContentItem">
              <div class="numberStrategie numberStrategieForInfo"><span>4</span></div>
              <div class="infoWindowContentItemText">
                {{ $store.state.translation[$store.state.lang].details4 }}
              </div>
          </div>

          <div class="infoWindowContentItem">
              <div class="numberStrategie numberStrategieForInfo"><span>5</span></div>
              <div class="infoWindowContentItemText">
                {{ $store.state.translation[$store.state.lang].details5 }}
              </div>
          </div>
      </div>

      <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg" class="closeButton" v-on:click="closeInfo">
          <path d="M27 13.5C27 20.9558 20.9558 27 13.5 27C6.04416 27 0 20.9558 0 13.5C0 6.04416 6.04416 0 13.5 0C20.9558 0 27 6.04416 27 13.5Z" fill="#4F4F4F" class="closeButtonBg"/>
          <path d="M11.4465 8.73529L13.5496 12.4453L15.6714 8.73529H17.7001L14.7284 13.5L17.725 18.2647H15.6962L13.5496 14.7036L11.4092 18.2647H9.37431L12.3398 13.5L9.41153 8.73529H11.4465Z" fill="white"/>
      </svg>

  </div>
</div>
</template>

<script>
import vSelect from './components/v-select.vue'

export default {
  name: 'App',

  components: {
    vSelect
  },

  methods: {
    openInfo() {
      let infoWindow = document.getElementById('info');
      document.body.style.overflow = 'hidden'
      infoWindow.classList.remove('fadeOut');
      infoWindow.classList.add('fadeIn');
    },

    closeInfo() {
      let infoWindow = document.getElementById('info');
      document.body.style.overflow = 'auto'
      infoWindow.classList.remove('fadeIn');
      infoWindow.classList.add('fadeOut');
    },

    openCloseMenu() {
      let el1 = document.getElementsByClassName('firstMenuBut');
      let el2 = document.getElementsByClassName('secondMenuBut');
      let menu = document.getElementById('menu');
      let nav = document.getElementsByClassName('nav nav-active');

      if (el1[0].classList.length == 1) {
          el1[0].classList.add('is-active');
          el2[0].classList.add('is-active');
          nav[0].classList.add('is-active');
          menu.classList.add('is-active');
          document.body.style.overflow = 'hidden'
      } else {
          el1[0].classList.remove('is-active');
          el2[0].classList.remove('is-active');
          nav[0].classList.remove('is-active');
          menu.classList.remove('is-active');
          document.body.style.overflow = 'auto'
      }
    },

    
  },

  created() {
    let cookieLang = document.cookie.split(';').map((i) => i.trim()).find((j) => j.slice(0, 4) == 'lang') ? document.cookie.split(';').map((i) => i.trim()).find((j) => j.slice(0, 4) == 'lang').slice(5) : undefined
    let userLang = (navigator.language || navigator.userLanguage).slice(0, 2)

    if (cookieLang != undefined) {
      if (Object.keys(this.$store.state.translation).indexOf(cookieLang) != -1) {
        this.$store.dispatch('setLang', cookieLang)
        document.documentElement.lang = cookieLang
      } else {
        if (Object.keys(this.$store.state.translation).indexOf(userLang) != -1) {
          this.$store.dispatch('setLang', userLang)
          document.cookie = encodeURIComponent('lang') + '=' + encodeURIComponent(userLang)
          document.documentElement.lang = userLang
        } else {
          this.$store.dispatch('setLang', 'en')
          document.cookie = encodeURIComponent('lang') + '=' + encodeURIComponent('en')
          document.documentElement.lang = 'en'
        }
      }
    } else {
      if (Object.keys(this.$store.state.translation).indexOf(userLang) != -1) {
        this.$store.dispatch('setLang', userLang)
        document.cookie = encodeURIComponent('lang') + '=' + encodeURIComponent(userLang)
        document.documentElement.lang = userLang
      } else {
        this.$store.dispatch('setLang', 'en')
        document.cookie = encodeURIComponent('lang') + '=' + encodeURIComponent('en')
        document.documentElement.lang = 'en'
      }
    }
  },

  mounted() {
    const anchors = document.querySelectorAll('a[href*="#"]')

    for (let anchor of anchors) {
      anchor.addEventListener('click', function (e) {
        e.preventDefault()
        
        const blockID = anchor.getAttribute('href').substr(1)
        
        document.getElementById(blockID).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      })
    }

    const url = new URL(window.location.href);
    const ref = url.searchParams.get("ref") || localStorage.getItem('ref');
    let query = ''
    if (ref) {
        query = `/?ref=${ref}`
        localStorage.setItem('ref', ref)
    }

    document.querySelectorAll('.login').forEach((el) => {
        el.onclick = () => window.location = `https://app.aurous.finance${query}`
    })
  }
}
</script>

<style>

body {
  margin: 0;
}

#app {
  font-family: 'Inter';
  font-style: normal;
  background: black;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}


.container {
  width: 100%;
  max-width: 1920px;
  margin: auto;
}

/* first window */

.firstWindow {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='1500' viewBox='0 0 1920 1500' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='1920' height='1042' fill='url(%23paint0_linear_2_4)'/%3E%3Cpath d='M-71.5 1072.5C-71.5 1072.5 653.5 602.5 955.5 681.5C1257.5 760.5 1754 277.5 1724 210.43C1694 143.36 1920 28 1920 28V1320.5L1407 1340L938 1474L-34.5 1499.5L-71.5 1072.5Z' fill='url(%23paint1_linear_2_4)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_2_4' x1='-82' y1='-166.5' x2='2163.5' y2='1093' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%237E5A1D'/%3E%3Cstop offset='0.447917' stop-color='%23CBA136'/%3E%3C/linearGradient%3E%3ClinearGradient id='paint1_linear_2_4' x1='7.50001' y1='638' x2='2025' y2='669.5' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%230E0E0E'/%3E%3Cstop offset='0.453378' stop-color='%2320201E'/%3E%3Cstop offset='0.671875' stop-color='%23222220'/%3E%3Cstop offset='1' stop-color='%23191918'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;

  display: flex;
  flex-direction: column;
}

.firstWindowBgBottom {
  object-fit: contain;
  width: 100%;
  margin-top: 100px;
}

/* header */

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 88%;
  height: 100%;
  max-width: 1440px;
  margin-top: 42px;
  margin-left: auto;
  margin-right: auto;
}

.logo {
  display: flex;
  align-items: center;

  font-family: 'Kanit';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 91%;

  color: #FFFFFF;
  cursor: default;
}

.logo img{
  width: 59.76px;
  height: 68px;
  margin-right: 10px;
}

.nav {
  display: flex;
  gap: 52px;
}

.nav a,
.nav div {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  cursor: pointer;
  color: #FFFFFF;
  text-decoration-line: none;
}

.nav a:hover {
  text-decoration-line: underline;
}

.login {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #222222;
  padding: 4px 10px;
  border-radius: 8px;
  transition: all .2s;
}

.login svg {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}

.login:hover {
  background: #484848;    
}

.menuWindow {
  position: fixed;
  width: 100%;
  height: 0;
  top: 0;
  z-index: 1100;
  background: linear-gradient(106.93deg, #7E5A1D -7%, #CBA136 45.79%);
  transition: .5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav.nav-active {
  display: flex;
  visibility: hidden;
  transition: .5s;
  opacity: 0;
}

.nav.is-active {
  visibility: visible;
  opacity: 1;
}

.menuWindow.is-active {
  height: 100vh;
}

.menuButton {
  display: none;
  position: relative;
  height: 16px;
  width: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 5px;
  transition: all .2s;
  z-index: 1110;
  cursor: pointer;
}

.firstMenuBut, 
.secondMenuBut {
  position: absolute;

  display: block;
  content: '';
  width: 100%;
  height: 1px;
  background: #ffffff;

  transition: all .2s;
}

.firstMenuBut {
  top: 28%;
}

.secondMenuBut {
  bottom: 28%;
  width: 75%;
}

.menuButton.is-active {
  height: 16px;
}

.firstMenuBut.is-active {
  transform: rotate(-45deg);
  top: calc(50% - 1px);
}

.secondMenuBut.is-active {
  width: 100%;
  bottom: calc(50%);
  transform: rotate(45deg);
}

/* main */

.firstWindowMain {
  width: 88%;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 42px;
  display: flex;
  align-items: center;

  position: relative;
}

.firstWindowMainTexts {
  position: absolute;
  left: 0;
  top: 20%;
}

.firstWindowMainTitle {
  font-weight: 800;
  font-size: 64px;
  line-height: 94.02%;
  text-transform: uppercase;
  color: #FFFFFF;
  max-width: 700px;
}

.firstWindowMainText {
  font-weight: 300;
  font-size: 24px;
  line-height: 29px;
  color: #FFFFFF;
  margin: 25px 0;
  max-width: 510px;
}

.firstWindowImgPosition {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
}

.firstWindowImg {
  object-fit: contain;
  width: 970px;
}

.firstWindowMainSocial {
  position: absolute;
  top: 100%;
  display: flex;
  gap: 33px;
  z-index: 100;
}

.firstWindowMainSocial a {
  border-radius: 50%;
  padding: 0;
  height: 28px;
  transition: all .2s;
}

.activeSocial {
  transition: .2s;
}

.firstWindowMainSocial a:hover .activeSocial {
  fill: rgb(124, 124, 124);
}

/* second window */

.firstAToken {
  position: absolute;
  width: 40px;
  height: 45px;
  transform: rotate(11.5deg);
  bottom: 18%;
  right: 3%;
}

.secondWindow {
  background: linear-gradient(270.49deg, #090909 0.76%, #151515 54.15%, #090909 99.92%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.secondWindow svg {
  width: 100%;
}

.secondWindowWidth {
  max-width: 1300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.secondWindowTitle {
  font-weight: 600;
  font-size: 92px;
  line-height: 98.52%;
  text-transform: uppercase;
  margin: 110px 0 87px 0;

  color: #FFFFFF;
}

.titleFirst {
  color: #B99332;
}

.titleSecond {
  color: #CBA136;
}

.advantages {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 18%;
}

.advantagesItem img {
  width: 100px;
}

.advantagesItemTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-transform: uppercase;
  color: #ffffff;
  /* background: linear-gradient(180deg, #996E32 0%, #CA9D52 72.46%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; */

  margin: 11px 0 4px 0;
}

.advantagesItemText {
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #CA9D52;
}

.experience {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 115px;
}

.experience img {
  width: 580px;
}

.experienceContent {
  position: relative;
}

.verticalStripe {
  position: absolute;
  z-index: 100;
  top: 0;
  left: -20px;
  height: 100%;
  width: 6px;
  border: solid 0;
  border-radius: 3px;
  background: #ad813f;
}

.experienceItem {
  font-weight: 300;
  font-size: 16px;
  line-height: 127.02%;
  margin-bottom: 22px;
  width: 335px;

  color: #B99332;
}

.experienceItem div {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #B99332;

  margin: 22px 0;

  position: relative;
}

.horizontalStripe {
  height: 0;
}

.horizontalStripe div {
  position: absolute;
  z-index: 101;
  bottom: -17px;
  left: -40px;
  height: 15px;
  width: 135px;
  background: #B99332;
  border: 5px solid #0C0D0E;
  border-radius: 20px;
}

/* third window */

.secondAToken {
  position: absolute;
  width: 40px;
  height: 45px;
  transform: rotate(-47deg);
  top: 3%;
  left: 8%;
}

.firstBToken {
  position: absolute;
  width: 43.85px;
  height: 38.97px;
  transform: matrix(0.67, -0.99, 0.55, 0.69, 0, 0);

  top: 25%;
  right: 9%;
}

.secondBToken {
  position: absolute;
  width: 43.85px;
  height: 38.97px;
  transform: matrix(0.71, 0.52, -0.96, 0.7, 0, 0);

  bottom: 22%;
  left: 9%;
}

.thirdWindow {
  background: linear-gradient(270.49deg, #090909 0.76%, #151515 54.15%, #090909 99.92%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.bg2Bottom {
  object-fit: contain;
  width: 100%;
}

.roadmapBg {
  position: absolute;
  width: 630px;
  left: -10%;
  top: 30%;
}

.roadmapBg2 {
  display: none;
  position: absolute;
  width: 381px;
  right: -7%;
  bottom: 22%;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.thirdWindowWidth {
  position: relative;
  max-width: 1290px;
  width: 100%;
  margin-top: 77px;
  margin-bottom: 110px;
}

.roadmapDescription {
  width: 100%;
  font-weight: 500;
  font-size: 22px;
  line-height: 98.52%;
  text-transform: uppercase;

  color: #5E5E5E;

  display: flex;
  align-items: flex-end;

}

.roadmapDescription span {
  margin-right: 8px;
}

.dottetLine {
  height: 3px;

  background: linear-gradient(to right, #5E5E5E 50%, rgba(255, 255, 255, 0) 0%);
  background-position: top;
  background-repeat: repeat-x;
  background-size: 24px 1px;
  
  -webkit-flex: auto;
  flex: auto;
}

.dottetLineHoriz {
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, #5E5E5E 50%, rgba(255, 255, 255, 0) 0%);
  background-position: center;
  background-repeat: repeat-x;
  background-size: 24px 1px;
  -webkit-flex: auto;
  flex: auto;
}

.dottetLineHoriz.for1000 {
  display: none;
}

.dottetLineVertical {
  width: 2px;
  background: linear-gradient(#5E5E5E 50%, rgba(255, 255, 255, 0) 0%);
  background-position: center;
  background-repeat: repeat-y;
  background-size: 1px 24px;
}


.roadmapTitle {
  font-weight: 500;
  font-size: 95px;
  line-height: 98.52%;

  text-transform: uppercase;

  color: #FFFFFF;

  margin: 20px 0 95px 0;
}

.secondRoadmapTitle {
  background: linear-gradient(90.69deg, #947711 0%, #F38B10 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.firstRoadmapTitle {
  background: linear-gradient(90.69deg, #E3DB11 0%, #7B7600 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.roadmapMain {
  background: rgba(20, 20, 20, 0.27);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.roadmapMainItem {
  width: calc(50% - 1.5px);
  height: 378px;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 60px;
}

.roadmapMainItem img {
  width: 80px;
  margin-right: 30px;
}

.roadmapMainItemText {
  font-weight: 700;
  font-size: 48px;
  line-height: 58px;
  color: #FFFFFF;
}

.roadmapMainItemText div {
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #FFFFFF;
  margin-top: 4px;
}

/* fourth window */

.fourthWindow {
  background: radial-gradient(38.78% 38.78% at 50% 61.22%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(90.26deg, #191918 0.13%, #222220 40.56%, #0E0E0D 99.69%), linear-gradient(270.49deg, #090909 0.76%, #151515 54.15%, #090909 99.92%);
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.fourthWindowWidth {
  max-width: 1290px;
  width: 100%;
  margin-top: 180px;  
}

.fourthWindowSvg {
  width: 100%;
}

.fourthWindowTitle {
  font-weight: 500;
  font-size: 95px;
  line-height: 98.52%;
  text-transform: uppercase;
  color: #FFFFFF;
}

.fourthWindowTitle1 {
  background: linear-gradient(90.99deg, #EBBC17 2.36%, #BA7B00 71.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.fourthWindowTitle2 {
  background: linear-gradient(90.99deg, #F0D800 2.36%, #E1B000 81.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.tokens {
  margin: 70px 0 105px 0;
  display: flex;
  justify-content: space-between;
}

.token {
  display: flex;
  flex-wrap: wrap;
  width: 43%;
}

.dottetLineHorizToken {
  height: 2px;
  width: 100%;
  background: linear-gradient(to right, #5E5E5E 50%, rgba(255, 255, 255, 0) 0%);
  background-position: center;
  background-repeat: repeat-x;
  background-size: 24px 1px;
  -webkit-flex: auto;
  flex: auto;
}

.dottetLineVerticalToken {
  width: 2px;
  background: linear-gradient(#5E5E5E 50%, rgba(255, 255, 255, 0) 0%);
  background-position: center;
  background-repeat: repeat-y;
  background-size: 1px 24px;
}

.tokenInfo {
  width: calc(100% - 5px);
  padding: 38px 53px;

  height: calc(100% - 5px);
}

.tokenInfoName {
  font-weight: 500;
  font-size: 22px;
  line-height: 98.52%;
  text-transform: uppercase;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.tokenInfoName img {
  width: 105px;
  margin-right: 22px;
}

.tokenInfoTitle {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 22px;
  line-height: 98.52%;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 30px;
}

.tokenInfoTitle span {
  color: #5E5E5E;
}

.tokenInfoText {
  font-weight: 500;
  font-size: 16px;
  line-height: 115.02%;

  color: #838383;
}

.strategies {
  margin-bottom: 100px;
}

.strategieTitle {
  font-weight: 700;
  font-size: 24px;
  line-height: 98.52%;
  text-transform: uppercase;
  color: #5E5E5E;
  text-align: center;
  margin-bottom: 23px;
}

.strategieMain {
  display: flex;
  flex-wrap: wrap;
  
}

.strategieItems {
  width: calc(100% - 4.5px);
  display: flex;
  justify-content: center;
  padding: 55px;
}

.strategieItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
}

.strategieItem img {
  width: 54px;
}

.strategieItem {
  font-weight: 700;
  font-size: 20px;
  line-height: 98.52%;
  text-transform: uppercase;
  color: #FFFFFF;
}

.verticalDivider {
  border: 1px solid #383636;
  border-radius: 1px;
  height: 100%;
  margin: 0 20px;
}

.strategieItemInfo {
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  gap: 21px;
  align-items: center;
}

.numberStrategie {
  width: 20.65px;
  height: 20.65px;
  background: #383636;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numberStrategie span {
  font-weight: 700;
  font-size: 12px;
  line-height: 115%;
  color: #FFFFFF;
}

.strategieItemInfo svg {
  cursor: pointer;
}

.strategieItemInfoIconText {
  font-weight: 700;
  font-size: 18px;
  line-height: 98.52%;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 9px;
}

.strategieItemInfo svg circle,
.strategieItemInfo svg path {
  transition: all .2s;
}

.strategieItemInfo svg:hover circle {
  fill: #635f5f;
}

.strategieItemInfo svg:hover path {
  fill: #292727;
}

.forTitle {
  width: 25px !important;
}

.strategieItemTitle {
  display: flex;
  align-items: center;
  gap: 7px;
  height: 20px;
}

.strategieLineThrough {
  font-weight: 400;
  font-size: 14px;
  line-height: 98.52%;
  text-align: center;
  text-decoration-line: line-through;

  color: #FFFFFF;
}

.info {
  width: 23px;
  height: 23px;
  background: #FFFFFF;
  border-radius: 100%;
  border: 1px solid #383636;
}

.thirdAToken {
  position: absolute;
  width: 31.8px;
  height: 35.78px;
  transform: rotate(-21.5deg);
  top: 23%;
  left: 4%;
}

.thirdBToken {
  position: absolute;
  width: 43.85px;
  height: 38.97px;
  transform: matrix(0.89, -0.76, 0.35, 0.82, 0, 0);
  top: 35%;
  right: 2.5%;
}

.fourthBToken {
  position: absolute;
  width: 43.85px;
  height: 38.97px;
  transform: matrix(0.89, -0.76, 0.35, 0.82, 0, 0);
  top: 63%;
  left: 9%;
}

.fourthAToken {
  position: absolute;
  width: 31.8px;
  height: 35.78px;
  transform: rotate(-21.5deg);
  top: 70%;
  right: 12%;
}

/* fifth window */

.fifthWindow {
  background: radial-gradient(38.78% 38.78% at 50% 61.22%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(90.26deg, #191918 0.13%, #222220 40.56%, #0E0E0D 99.69%), linear-gradient(270.49deg, #090909 0.76%, #151515 54.15%, #090909 99.92%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.fifthWindowWidth {
  position: relative;
  max-width: 1290px;
  width: 100%;
  margin-top: 75px; 
}

.fifthWindowTitle {
  font-weight: 500;
  font-size: 86px;
  line-height: 98.52%;
  text-transform: uppercase;
  color: #FFFFFF;
}

.fifthWindowTitle span {
  background: linear-gradient(90.99deg, #EBBD18 2.36%, #BC7E01 71.91%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.fifthWindowText {
  font-weight: 500;
  font-size: 22px;
  line-height: 27px;
  color: #636363;
  margin: 10px 0 55px 0;
}

.partnership {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.partnership img {
  min-width: 615px;
}

.partnershipText {
  font-weight: 300;
  font-size: 20px;
  line-height: 127.52%;
  color: #FFFFFF;
}

.partnershipLevels {
  display: flex;
  flex-wrap: wrap;
  gap: 15px 49px;
  margin-bottom: 90px;
}

.partnershipLevels span {
  width: 40%;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #DEA000;
}

/* footer */

footer {
  background-image: url("data:image/svg+xml,%3Csvg width='1920' height='607' viewBox='0 0 1920 607' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-2 33C748.593 102.994 1169.38 116.067 1920 0V607H-2V33Z' fill='url(%23paint0_linear_518_177)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_518_177' x1='121.984' y1='100.389' x2='1665.85' y2='984.329' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23C28C00'/%3E%3Cstop offset='1' stop-color='%23F7B50C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E"), radial-gradient(38.78% 38.78% at 50% 61.22%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(90.26deg, #191918 0.13%, #222220 40.56%, #0E0E0D 99.69%), linear-gradient(270.49deg, #090909 0.76%, #151515 54.15%, #090909 99.92%);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footerHeader {
  margin-top: 380px;
  margin-bottom: 50px;
}

.footerSocial {
  display: flex;
  justify-content: center;
  gap: 90px;
}

.footerSocialItem {
  display: flex;
  align-items: center;
  gap: 10px;

  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.footerSocialItem a {
  border-radius: 50%;
  padding: 0;
  height: 28px;
}

.footerSocialItem svg {
  cursor: pointer;
}

.footerSocialItem svg:hover .activeSocial {
  fill: rgb(124, 124, 124);
}

.footerRights {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
  margin: 100px 0 25px 0;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #FFFFFF;
}

.footerRights span {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

/* info window */

.infoWindow {
  background: rgba(54, 54, 54, 0.85);
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 1100;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: none; */

  opacity: 0;
visibility: hidden;
}

.infoWindowContent {
  max-width: 1280px;
  max-height: 450px;
  width: 100%;
  height: 100%;
  position: relative;
}

.infoWindowContentTitle {
  font-weight: 700;
  font-size: 42px;
  line-height: 100.02%;
  color: #FFFFFF;
  margin-bottom: 110px;
}

.infoWindowContentItems {
  display: flex;
  flex-wrap: wrap;
  gap: 39px 10%;
}

.infoWindowContentItem {
  width: 42%;
  display: flex;
  gap: 6px;
}

.numberStrategieForInfo {
  min-width: 20.65px;
  min-height: 20.65px;
  background: #161616;
}

.infoWindowContentItemText {
  font-weight: 500;
  font-size: 18px;
  line-height: 115.02%;
  color: #FFFFFF;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

.closeButtonBg {
  transition: .2s;
}

.closeButton:hover .closeButtonBg {
  fill: #6f6f6f;
}

@keyframes fadeIn { 
	from {
		opacity: 0;
		visibility: hidden;
	}
	to { 
		opacity:1;
		visibility: visible; 
	}
}
.fadeIn {
	animation-name: fadeIn;
	opacity: 1;
	visibility: visible;
}

@keyframes fadeOut { 
	from {
		opacity: 1;
		visibility: visible;
	}
	to {
		opacity:0;
		visibility: hidden;
	}
}
.fadeOut {
	animation-name: fadeOut;
	opacity: 0;
	visibility: hidden;
}

.fadeIn, .fadeOut{
	animation-duration: 0.2s;
	animation-timing-function: linear;
}

.strategieItemHoriz {
  display: none;
}   

.r32 {
  display: none;
}

.strategieItemHoriz1 {
  display: none;
}

.dottetLineHoriz.fiftW {
  display: none;
}

.partnershipTextImgSome {
  display: none;
}


/* ---------------------------------------------- */
/* media */

/* 1700 - 1440 */
@media screen and (max-width: 1700px) {
  header {
      max-width: 1240px;
  }

  .logo img {
      width: 41px;
      height: 47px;
      margin-right: 10px;
  }

  .logo {
      font-size: 18px;
  }

  .nav a,
  .nav div {
      font-size: 14px;
      line-height: 17px;
  }

  .login {
      font-size: 14px;
      line-height: 17px;
  }

  .firstWindowImg {
      width: 816px;
  }

  .firstWindowMain {
      width: 88%;
      max-width: 1240px;
  }

  .firstWindowBgBottom {
      margin-top: 80px;
  }

  .firstWindowMainTitle {
      font-size: 54px;
      max-width: 620px;
  }

  .firstWindowMainText {
      font-size: 18px;
      line-height: 22px;
      margin: 23px 0;
      max-width: 410px;
  }

  .secondWindowTitle {
      font-size: 72px;
      margin: 110px 0 67px 0;
  }

  .secondWindowWidth {
      max-width: 1000px;
  }

  .advantagesItemTitle {
      font-size: 20px;
      line-height: 24px;
  }

  .advantagesItemText {
      font-size: 15px;
      line-height: 18px;
  }

  .experience {
      margin-top: 62px;
      gap: 80px;
  }

  .experience img {
      width: 485px;
  }

  .thirdWindowWidth {
      max-width: 1010px;
      margin-top: 50px;
      margin-bottom: 90px;
  }

  .roadmapDescription {
      font-size: 20px;
  }

  .roadmapTitle {
      font-size: 72px;
      margin: 20px 0 65px 0;
  }

  .roadmapMainItemText {
      font-size: 38px;
      line-height: 46px;
  }

  .roadmapMainItemText div {
      font-size: 16px;
      line-height: 19px;
  }

  .roadmapMainItem img {
      width: 80px;
      margin-right: 26px;
  }

  .roadmapMainItem {
      padding: 50px;
  }

  .roadmapBg {
      width: 468px;
  }

  .fourthWindowWidth {
      max-width: 1010px;
      margin-top: 100px;
  }

  .fourthWindowTitle {
      font-size: 72px;
  }

  .tokens {
      margin: 50px 0 85px 0;
  }

  .token {
      width: 47%;
  }

  .tokenInfoName img {
      width: 89px;
  }

  .tokenInfoName {
      font-size: 18px;
  }

  .tokenInfoTitle {
      font-size: 18px;
  }

  .tokenInfoText {
      font-size: 14px;
  }

  .tokenInfo {
      padding: 45px;
  }

  .strategieTitle {
      font-size: 20px;
  }

  .centerVert {
      display: none;
  }

  .strategieItems {
      flex-wrap: wrap;
      gap: 80px 0;
  }

  .strategieItem {
      width: 33%;
  }

  .verticalDivider {
      height: auto;
  }

  .strategieItem.secondStrat {
      width: 30%;
  }

  .fifthWindowWidth {
      max-width: 1010px;
      margin-top: 55px;
  }

  .fifthWindowTitle {
      font-size: 68px;
  }

  .fifthWindowText {
      font-size: 20px;
      line-height: 24px;
      margin: 10px 0 30px 0;
  }

  .partnership img {
      min-width: 516px;
  }

  .partnershipText {
      font-size: 18px;
  }

  .partnership {
      gap: 27px;
  }

  .partnershipLevels span {
      width: 40%;
      font-size: 20px;
      line-height: 24px;
  }

  .partnershipLevels {
      margin-bottom: 65px;
  }

  .footerHeader {
      margin-top: 260px;
  }

  .infoWindowContent {
      max-width: 1000px;
      max-height: 450px;
  }
}

/* 1440 - 1000 */
@media screen and (max-width: 1400px) {
  header {
      max-width: 768px;
      margin-top: 20px;
  }

  .nav {
      display: none;
  }

  .login {
      display: none;
  }

  .menuButton {
      display: flex;
  }

  .firstWindowMain {
      max-width: 800px;
  }

  .firstWindowImg {
      width: 590px;
  }

  .firstWindowMainTitle {
      font-size: 32px;
      max-width: 395px;
  }

  .firstWindowMainText {
      font-size: 16px;
      line-height: 19px;
      margin: 15px 0;
      max-width: 330px;
  }

  .firstWindowMainSocial a  {
      height: 22px;
  }

  .firstWindowMainSocial a svg {
      width: 22px;
      height: 22px;
  }

  .firstWindowBgBottom {
      margin-top: 30px;
  }

  .secondWindowWidth {
      max-width: 800px;
  }

  .secondWindowTitle {
      font-size: 54px;
      margin: 70px 0 50px 0;
  }

  .advantages {
      gap: 6%;
  }

  .experience img {
      width: 350px;
  }

  .experienceItem {
      font-size: 15px;
  }

  .experienceItem div {
      font-size: 20px;
      line-height: 24px;
  }

  .firstAToken {
      width: 31px;
      height: 35px;
  }

  .thirdWindowWidth {
      max-width: 764px;
  }

  .roadmapDescription {
      font-size: 18px;
  }

  .roadmapTitle {
      font-size: 54px;
  }

  .secondAToken {
      width: 31px;
      height: 35px;
      left: 5%;
  }

  .firstBToken {
      width: 36px;
      height: 32px;
      top: 25%;    
      right: 7%;
  }

  .secondBToken {
      width: 36px;
      height: 32px;
      bottom: 14%;    
      left: 6%;
  }

  .dottetLineVertical {
      display: none;
  }

  .dottetLineHoriz.for1000 {
      display: block;
  }

  .roadmapMainItem {
      width: 100%;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
  }

  .roadmapMainItemText {
      max-width: 331px;
      text-align: center;

  }

  .roadmapMainItem img {
      margin-right: 0;
  }

  .roadmapBg {
      width: 408px;
      left: -10%;
      top: 20%;
  }

  .roadmapBg2 {
      display: block;
  }

  .fourthWindowWidth {
      max-width: 766px;
      margin-top: 80px;
  }

  .fourthWindowTitle {
      font-size: 54px;
  }

  .tokenInfo {
      padding: 37px 31px;
  }

  .tokenInfoName img {
      width: 87px;
  }

  .token {
      width: 48.5%;
  }

  .tokens {
      margin: 50px 0 62px 0;
  }

  .strategieTitle {
      font-size: 18px;
  }

  .strategieItem {
      width: 40%;
  }

  .strategieItem.secondStrat {
      width: 278px;
  }

  .verticalDivider.secondStrat {
      display: none;
  }

  .strategieItems.secondStrateg {
      flex-wrap: nowrap;
      gap: 30px;
      flex-direction: column;
      align-items: center;
      padding: 42px;
  }

  .strategieItemHoriz {
      display: block;
      width: 278px;
      border: 1px solid #383636;
      border-radius: 1px;
  }

  .thirdAToken {
      width: 28.8px;
      height: 32.5px;
  }

  .thirdBToken {
      width: 32px;
      height: 30px;
  }

  .fourthBToken {
      width: 32px;
      height: 30px;
      top: 58%;
      left: 6%;
  }

  .fourthAToken {
      width: 28.8px;
      height: 32.5px;
      top: 75%;
      right: 6%;
  }

  .strategies {
      margin-bottom: 90px;
  }

  .infoWindowContent {
      max-width: 760px;
      max-height: 540px;
  }

  .infoWindowContentItem {
      width: 72%;
  }

  .fifthWindowWidth {
      max-width: 760px;
  }

  .fifthWindowTitle {
      font-size: 48px;
  }

  .fifthWindowText {
      font-size: 18px;
      line-height: 22px;
      margin: 9px 0 30px 0;
      max-width: 678px;
  }

  .partnership img {
      min-width: 360px;
  }

  .partnershipLevels span {
      width: 40%;
      font-size: 16px;
      line-height: 19px;
  }

  .partnershipText {
      font-size: 15px;
  }

  .partnershipLevels {
      margin-bottom: 49px;
  }

  .footerHeader {
      margin-top: 195px;
      margin-bottom: 30px;
      justify-content: center;
  }

  .footerRights {
      margin: 80px 0 20px 0;
  }

  .roadmapMainItemText.r2 {
      max-width: 410px;
  }

  .roadmapMainItemText.r3 {
      max-width: 520px;   
  }

  .r31 {
      display: none;
  }

  .r32 {
      display: block;
  }

  .roadmapMainItemText.r4 {
      max-width: 357px;   
  }
}

/* 1000 - 760 */
@media screen and (max-width: 990px) {
  .nav.nav-active {
      flex-direction: column;
      align-items: center;
      gap: 23px;
  }

  .logo {
      font-size: 14px;
  }

  .logo img {
      width: 28px;
      height: 33px;
      margin-right: 6px;
  }

  header {
      max-width: 528px;
  }

  .firstWindowMain {
      max-width: 550px;
  }

  .firstWindowImg {
      width: 401px;
  }

  .firstWindowMainTitle {
      font-size: 24px;
      max-width: 260px;
  }

  .firstWindowMainText {
      font-size: 14px;
      line-height: 17px;
      margin: 7px 0;
      max-width: 230px;
  }

  .firstWindowMainSocial a svg {  
      width: 20px;
      height: 20px;
  }

  .firstWindowBgBottom {
      margin-top: 20px;
  }

  .secondWindowWidth {
      max-width: 500px;
  }

  .secondWindowTitle {
      font-size: 34px;
      margin: 60px 0 50px 0;
  }

  .advantages {
      flex-direction: column;
      gap: 40px;
  }

  .advantagesItem img {
      width: 85px;
  }

  .advantagesItemTitle {
      font-size: 18px;
      line-height: 22px;
      margin-top: 6px;
  }

  .experience {
      margin-top: 44px;
      background: rgba(20, 20, 20, 0.27);
      backdrop-filter: blur(4.5px);
      position: relative;
  }

  .experienceContent {
      position: relative;
      padding-left: 50px;
      background: rgba(20, 20, 20, 0.27);
      -webkit-backdrop-filter: blur(4.5px);
      backdrop-filter: blur(4.5px);
  }

  .experience img {
      width: 242px;
      position: absolute;
      left: -35%;
  }

  .verticalStripe {
      left: 30px;
  }

  .firstAToken {
      width: 28px;
      height: 31px;
      bottom: 20%;
      right: 5%;
  }

  .thirdWindowWidth {
      max-width: 490px;
      margin-top: 33px;
  }

  .roadmapDescription {
      font-size: 16px;
  }

  .roadmapTitle {
      font-size: 34px;
      margin: 16px 0 60px 0;
  }

  .roadmapBg {
      width: 308px;
      left: -10%;
      top: 12%;
  }

  .roadmapMainItemText {
      font-size: 28px;
      line-height: 34px;
      max-width: 260px;
  }

  .roadmapMainItemText div {
      font-size: 15px;
      line-height: 18px;
  }

  .roadmapMainItemText.r4 {
      max-width: 300px;   
  }

  .fourthWindowWidth {
      max-width: 480px;
      margin-top: 70px;
  }

  .fourthWindowTitle {
      font-size: 34px;
  }

  .tokens {
      margin: 44px 0 62px 0;
      flex-direction: column;
      gap: 34px;
  }

  .token {
      width: 100%;
  }

  .tokenInfo {
      padding: 45px;
  }

  .strategieItems {
      flex-wrap: nowrap;
      gap: 30px;
      flex-direction: column;
      align-items: center;
  }

  .strategieItem {
      width: 75%;
  }

  .verticalDivider {
      display: none;
  }

  .strategieItemHoriz1 {
      display: block;
      width: 278px;
      border: 1px solid #383636;
      border-radius: 1px;
  }

  .infoWindowContent {
      max-width: 480px;
      max-height: 535px;
  }

  .infoWindowContentItemText {
      font-size: 16px;
  }

  .infoWindowContentItem {
      width: 100%;
  }

  .infoWindowContentTitle {
      margin-bottom: 80px;
  }

  .strategies {
      margin-bottom: 20px;
  }

  .fifthWindowWidth {
      max-width: 490px;
      margin-top: 50px;
      background: rgba(20, 20, 20, 0.27);
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
  }

  .fifthWindow  {
      position: relative;
      overflow: hidden;
  }

  .partnershipTextImgSome {
      display: block;
      position: absolute;
      top: 22%;
      right: -10%;
      width: 290px;
  }

  .partnership img {
      display: none;
  }

  .dottetLineHoriz.fiftW {
      display: block;
  }

  .fifthWindowTitle {
      margin: 23px 20px 0 20px;
      font-size: 30px;
  }

  .fifthWindowText {
      font-size: 15px;
      line-height: 18px;
      margin: 9px 20px 24px 20px;
      max-width: auto;
  }   

  .partnership {
      margin: 0 20px 23px 20px;
  }

  .partnershipLevels {
      max-width: 300px;
  }

  .partnershipLevels {
      margin-bottom: 20px;
      gap: 6px 8px;
  }

  .partnershipLevels span {
      width: 48%;
      font-size: 15px;
      line-height: 18px;
  }

  footer {
      background-image: url("data:image/svg+xml,%3Csvg width='987' height='348' viewBox='0 0 987 348' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-4 18.9193C386.918 59.0477 606.071 66.5427 997 0V348H-4V18.9193Z' fill='url(%23paint0_linear_544_488)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_544_488' x1='60.5725' y1='57.5539' x2='900.89' y2='494.621' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23C28C00'/%3E%3Cstop offset='1' stop-color='%23F7B50C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E"), radial-gradient(38.78% 38.78% at 50% 61.22%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(90.26deg, #191918 0.13%, #222220 40.56%, #0E0E0D 99.69%), linear-gradient(270.49deg, #090909 0.76%, #151515 54.15%, #090909 99.92%);
  }

  .footerHeader {
      margin-top: 150px;
  }

  .footerRights {
      margin: 65px 0 15px 0;
  }
}

/* 760 -  */
@media screen and (max-width: 750px) {
  header {
      max-width: 350px;
  }

  .firstWindowMainTexts {
      position: static;
  }

  .firstWindowMain {
      max-width: 328px;
      margin-top: 20px;
  }

  .firstWindowImgPosition {
      flex-direction: column;
      align-items: center;
      gap: 10px;
  }

  .firstWindowImg {
      width: 328px;
  }

  .firstWindowMain {
      flex-direction: column;
      gap: 18px;
  }

  .firstWindowMainSocial {
      position: static;
      gap: 14px;
  }

  .firstWindowMainTitle {
      font-size: 20px;
      max-width: 230px;
      text-align: center;
  }

  .firstWindowMainText {
      font-size: 12px;
      line-height: 15px;
      margin: 7px 0;
      max-width: 240px;
      text-align: center;
  }

  .secondWindowWidth {
      max-width: 352px;
  }

  .secondWindowTitle {
      font-size: 24px;
      margin: 40px 0 30px 0;
  }

  .thirdWindowWidth {
      max-width: 352px;
      margin-top: 0;
  }

  .thirdWindow {
      overflow: hidden;
  }

  .roadmapDescription span {
      margin-right: 4px;
      font-size: 14px;
  }

  .roadmapTitle {
      font-size: 24px;
      margin: 12px 0 50px 0;  
  }

  .secondAToken {
      display: none;
  }

  .roadmapMainItem {
      padding: 30px;
  }

  .roadmapBg2 {
      width: 300px;
      right: -20%;
  }

  .roadmapBg {
      width: 290px;
      left: -15%;
      top: 12%;
  }

  .thirdWindowWidth {
      margin-bottom: 45px;
  }

  .fourthWindowWidth {
      max-width: 351px;
      margin-top: 45px;
  }

  .fourthWindowTitle {
      font-size: 24px;
      max-width: 340px;
  }

  .tokens {
      margin: 25px 0 50px 0;
      gap: 20px;
  }

  .tokenInfo {
      padding: 35px;
  }

  .tokenInfoName img {
      width: 80px;
  }

  .tokenInfoName {
      font-size: 16px;
  }

  .tokenInfoTitle {
      font-size: 16px;
  }

  .tokenInfoText {
      font-size: 12px;
  }

  .experienceItem {
      width: 290px;
  }

  .strategieItems {
      padding: 40px 0;
  }

  .strategies {
      margin-bottom: 30px;
  }

  .strategieTitle {
      margin-bottom: 16px;
  }

  .fifthWindowWidth {
      max-width: 352px;
      margin-top: 10px;
  }

  .partnershipTextImgSome {
      width: 200px;
  }

  .fifthWindowTitle {
      margin: 17px 18px 0 18px;
      font-size: 22px;
  }

  .fifthWindowText {
      margin: 9px 18px 16px 18px;
  }

  .partnershipLevels {
      margin-bottom: 16px;
  }

  .partnership {
      margin: 0 18px 20px 18px;
  }

  footer {
      background-image: url("data:image/svg+xml,%3Csvg width='759' height='421' viewBox='0 0 759 421' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 18.4843C298.753 57.6903 466.238 65.0129 765 0V425.5L0 445.5V18.4843Z' fill='url(%23paint0_linear_544_750)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_544_750' x1='49.3486' y1='56.2308' x2='749.404' y2='341.047' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23C28C00'/%3E%3Cstop offset='1' stop-color='%23F7B50C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E"), radial-gradient(38.78% 38.78% at 50% 61.22%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(90.26deg, #191918 0.13%, #222220 40.56%, #0E0E0D 99.69%), linear-gradient(270.49deg, #090909 0.76%, #151515 54.15%, #090909 99.92%);
  }

  .footerHeader {
      margin-top: 250px;
  }

  .footerRights {
      margin: 85px 0 15px 0;
  }

  .footerSocialItem {
      flex-wrap: wrap;
      justify-content: center;
  }

  .footerSocialItem span {
      text-align: center;
      width: 100%;
  }

  .footerSocial {
      gap: 40px;
  }

  .infoWindowContent {
      max-width: 350px;
      max-height: 535px;
  }

  .infoWindowContentTitle {
      font-size: 32px;
      margin-bottom: 40px;
  }

  .infoWindowContentItemText {
      font-size: 15px;
  }

  .infoWindowContentItems {
      gap: 30px;
  }

  .thirdAToken {
      display: none;
  }

  .thirdBToken {
      display: none;
  }

  .fourthBToken {
      display: none;
  }

  .fourthAToken {
      display: none;
  }

}

@media screen and (max-width: 520px) {
  footer {
      background-image: url("data:image/svg+xml,%3Csvg width='480' height='403' viewBox='0 0 480 403' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M-2 18.5C159.982 57.7106 318.013 65.0206 480 0V403H-2V18.5Z' fill='url(%23paint0_linear_544_996)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_544_996' x1='41.4429' y1='55.2362' x2='463.312' y2='148.285' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%23C28C00'/%3E%3Cstop offset='1' stop-color='%23F7B50C'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E"), radial-gradient(38.78% 38.78% at 50% 61.22%, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 100%), linear-gradient(90.26deg, #191918 0.13%, #222220 40.56%, #0E0E0D 99.69%), linear-gradient(270.49deg, #090909 0.76%, #151515 54.15%, #090909 99.92%);
  }

  .footerRights {
      margin: 100px 0 25px 0;
  }

  .footerHeader {
      margin-top: 220px;
      margin-bottom: 60px;
  }
}

@media screen and (max-width: 450px) {
  .footerRights {
      margin: 70px 0 15px 0;
  }

  .footerHeader {
      margin-top: 180px;
      margin-bottom: 40px;
  }
}

@media screen and (max-width: 380px) {
  .footerRights {
      margin: 50px 0 15px 0;
  }

  .footerHeader {
      margin-top: 150px;
      margin-bottom: 20px;
  }
}

</style>