<template>
    <div class='v-select-lang'>
        <div class='nav-button-lang main-button' @click="openOptions" :class='{activeLang: areOptionsVisible}'>

            <div>
                
                <svg class="flag" v-if="$store.state.lang.toLowerCase() == 'en'" xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-gb" viewBox="0 0 640 480">
                    <path fill="#012169" d="M0 0h640v480H0z"/>
                    <path fill="#FFF" d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
                    <path fill="#C8102E" d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
                    <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
                    <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
                </svg>

                <svg class="flag" v-else-if="$store.state.lang.toLowerCase() == 'it'" xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-it" viewBox="0 0 640 480">
                    <g fill-rule="evenodd" stroke-width="1pt">
                        <path fill="#fff" d="M0 0h640v480H0z"/>
                        <path fill="#009246" d="M0 0h213.3v480H0z"/>
                        <path fill="#ce2b37" d="M426.7 0H640v480H426.7z"/>
                    </g>
                </svg>

                <svg class="flag" v-else-if="$store.state.lang.toLowerCase() == 'ru'" xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-ru" viewBox="0 0 640 480">
                    <g fill-rule="evenodd" stroke-width="1pt">
                        <path fill="#fff" d="M0 0h640v480H0z"/>
                        <path fill="#0039a6" d="M0 160h640v320H0z"/>
                        <path fill="#d52b1e" d="M0 320h640v160H0z"/>
                    </g>
                </svg>
                
                <span v-else>
                    {{ $store.state.lang.charAt(0).toUpperCase() + $store.state.lang.slice(1) }}
                </span>
                
            </div>
            
            <svg class="arrow" width="9" height="4" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 0.5L4.30476 3.33265C4.41711 3.42895 4.58289 3.42895 4.69524 3.33265L8 0.5" stroke="white" stroke-linecap="round"/>
            </svg>

        </div>

        <transition name="component-fade">
            <div class='options' v-if='areOptionsVisible'>
                <p v-for='option in options' :key='option' @click='selectOption(option)' class='noClick nav-button-lang'>

                    <svg class="flag" v-if="option == 'en'" xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-gb" viewBox="0 0 640 480">
                        <path fill="#012169" d="M0 0h640v480H0z"/>
                        <path fill="#FFF" d="M75 0l244 181L562 0h78v62L400 241l240 178v61h-80L320 301 81 480H0v-60l239-178L0 64V0h75z"/>
                        <path fill="#C8102E" d="M424 281l216 159v40L369 281h55zm-184 20l6 35L54 480H0l240-179zM640 0v3L391 191l2-44L590 0h50zM0 0l239 176h-60L0 42V0z"/>
                        <path fill="#FFF" d="M241 0v480h160V0H241zM0 160v160h640V160H0z"/>
                        <path fill="#C8102E" d="M0 193v96h640v-96H0zM273 0v480h96V0h-96z"/>
                    </svg>

                    <svg class="flag" v-else-if="option == 'it'" xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-it" viewBox="0 0 640 480">
                        <g fill-rule="evenodd" stroke-width="1pt">
                            <path fill="#fff" d="M0 0h640v480H0z"/>
                            <path fill="#009246" d="M0 0h213.3v480H0z"/>
                            <path fill="#ce2b37" d="M426.7 0H640v480H426.7z"/>
                        </g>
                    </svg>

                    <svg class="flag" v-else-if="option == 'ru'" xmlns="http://www.w3.org/2000/svg" id="flag-icon-css-ru" viewBox="0 0 640 480">
                        <g fill-rule="evenodd" stroke-width="1pt">
                            <path fill="#fff" d="M0 0h640v480H0z"/>
                            <path fill="#0039a6" d="M0 160h640v320H0z"/>
                            <path fill="#d52b1e" d="M0 320h640v160H0z"/>
                        </g>
                    </svg>

                    <span v-else>
                        {{ option.charAt(0).toUpperCase() +option.slice(1) }}
                    </span>
                </p>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'v-select-lang',

    data() {
        return {
            areOptionsVisible: false,
            options: Object.keys(this.$store.state.translation)
        }
    },
    methods: {
        closeWindow(event) {
            let close = true
            for (let i in event.composedPath()) {
                if (event.composedPath()[i].className == 'v-select-lang') {
                    close = false
                }
            }
            if (close) {
                this.areOptionsVisible = false
            }
        },
        selectOption(option) {
            this.$store.dispatch('setLang', option.toLowerCase())
            document.cookie = encodeURIComponent('lang') + '=' + encodeURIComponent(option.toLowerCase())
            document.documentElement.lang = option.toLowerCase()
            this.areOptionsVisible = false
        },
        openOptions() {
            this.areOptionsVisible = !this.areOptionsVisible
        },
    },

    mounted() {
        document.addEventListener('click', this.closeWindow, true)
        document.addEventListener('scroll', this.closeWindow, true)
    },

    beforeUnmount() {
        document.removeEventListener('click', this.closeWindow, true)
        document.removeEventListener('scroll', this.closeWindow, true)
    }
}
</script>

<style scoped>
    .v-select-lang {
        position: relative;
        width: min-content;
        cursor: pointer;
        -moz-user-select: none;
        -khtml-user-select: none;
        user-select: none;  

        color: #FFFFFF;

        padding: 0 5px;
    }

    .main-button {
        padding: 0 !important;
    }

    .main-button div {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .v-select-lang p {
        margin: 0;
    }

    .options {
        position: absolute;
        z-index: 1;
        top: 30px;
        left: 0;
        border-radius: 12px;
        background: rgba(141, 111, 36, 0.8);
        backdrop-filter: blur(2px);
        width: 38px;
    }

    .activeLang {
        text-decoration-line: underline;
    }

    .activeLang .flag {
        opacity: .5;
    }

    .activeLang .arrow {
        transform: scale(-1);
    }

    .nav-button-lang {
        padding: 7px;
        border-radius: 12px;
        transition: opacity .2s;
        max-width: 93px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        gap: 4px;
    }

    .nav-button-lang .flag {
        border-radius: 4px;
        height: 18px;
        transition: opacity .2s;
    }

    .nav-button-lang:hover .flag {
        opacity: .5;
    }

    .nav-button-lang span {
        padding: 8px 16px;
    }

    .nav-button-lang:hover {
        text-decoration-line: underline;
    }

    .nav-button-lang .arrow {
        transition: all .5s;
    }

    .nav-button-lang .arrow path {
        transition: all .2s;
        stroke: white;
    }

    .component-fade-enter-active, .component-fade-leave-active {
        transition: opacity .3s ease;
    }
    .component-fade-enter, .component-fade-leave-to {
        opacity: 0;
    }
    </style>